/* eslint-disable prefer-template */

/**
 * Build config
 *
 * This file should only contain configuration variables that are used at
 * compilation time.
 */

const HCo = {
	sources: {
		apiUrl: 'https://' + process.env.API_HOST + '.typography.com:' + process.env.API_PORT,
		dashboardUrl: 'https://' + process.env.DASHBOARD_HOST + '.typography.com',
		cloudfrontURL: 'https://d31td5fkd89rr1.cloudfront.net',
		sentry: 'https://d99309def4fc4ecfb0424dfedb0dc29b@sentry.io/176807',
		reCaptchaSiteKey: '6LcSa1sqAAAAAA9lCxwJrt4xkM9CsrJBbuJVZwjr'
	},

	google: {
		conversionID: 944784246,
		gtmID: 'GTM-NN2TRBB'
	}
};

module.exports = HCo;
