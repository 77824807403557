/**
 * The App state selectors
 */

import { createSelector } from 'reselect';
import { findLast, last, isString } from 'lodash';

const selectApp = () => (state) => state.get('app');
const selectBlog = () => (state) => state.get('blog');

/* Does the user have a session id */
export const selectSessionID = () => createSelector(
	selectApp(),
	appState => appState.get('sessionID')
);

/* Is this user authenticated? */
export const selectAuthState = () => createSelector(
	selectApp(),
	appState => appState.get('isAuthenticated')
);

/* Are we on a mobile device? */
export const selectMobileState = () => createSelector(
	selectApp(),
	appState => appState.get('isMobile')
);

/* Site footer state */
export const getCurrentRoutes = () => (state, props) => props.routes;
export const getCurrentRoute = () => (state, props) => last(props.routes);

export const selectFooter = () => createSelector(
	getCurrentRoutes(),
	(routes) => {
		const lastRouteDefiningFooterState = findLast(routes, i => typeof i.footer !== 'undefined');
		return lastRouteDefiningFooterState && lastRouteDefiningFooterState.footer || null;
	}
);

export const selectPageHeader = () => createSelector(
	getCurrentRoute(),
	(route) => route.pageHeader || false
);

/* Breadcrumb state */
export const selectBreadcrumbState = () => createSelector(
	selectApp(),
	appState => appState.get('breadcrumb').toJS()
);

/* LicenseBar visibility */
export const selectLicenseBarLocation = () => createSelector(
	selectApp(),
	appState => appState.get('licenseBarLocation')
);

/* Overlay state */
export const selectOverlayState = () => createSelector(
	selectApp(),
	appState => appState.get('overlayIsVisible')
);

/* Menu */
export const selectTertiaryMenu = () => createSelector(
	selectApp(),
	appState => {
		const selectedTertiaryMenu = appState.get('selectedTertiaryMenu');
		if (isString(selectedTertiaryMenu)) {
			return selectedTertiaryMenu;
		}
		return selectedTertiaryMenu && selectedTertiaryMenu.toJS();
	}
);


/* Breakpoints */
export const selectBreakpoints = () => createSelector(
	selectApp(),
	appState => appState.get('breakpoints').toJS()
);

// Errors
export const selectError = () => createSelector(
	selectApp(),
	appState => appState.get('error')
);

// Alert
export const selectAlert = () => createSelector(
	selectApp(),
	appState => appState.get('alert')
);

/* --- Site Menu ------------------------------------------------------------ */

export const selectMenu = () => createSelector(
	selectApp(),
	appState => appState.get('menu')
);

export const selectMenuContents = () => createSelector(
	selectApp(),
	appState => appState.get('menuContents')
);

/* --- Site Sub Menu -------------------------------------------------------- */

export const selectSubMenu = () => createSelector(
	selectApp(),
	appState => appState.get('secondaryMenu')
);

export const selectSubMenuSnapshot = () => createSelector(
	selectApp(),
	appState => appState.get('secondaryMenuSnapshot')
);


export const selectCounted = () => createSelector(
	selectApp(),
	appState => appState.get('counted')
);

/* --- Are Sub Menus and Additional Menu already Preloaded/Loaded ?-------------------------------------------------------- */

export const selectPreloadedSubMenus = () => createSelector(
	selectApp(),
	appState => appState.get('preloadedSubMenu').toJS()
);

export const selectPreloadedSubMenusLoaded = () => createSelector(
	selectApp(),
	appState => appState.get('preloadSubMenuSuccess')
);

export const selectLastAdditionalMenuFetched = () => createSelector(
	selectApp(),
	appState => appState.get('additionalMenuItemsFetched')
);

/* --- Is Main Menu Open -------------------------------------------------------- */

export const selectIsMenuOpen = () => createSelector(
	selectApp(),
	appState => appState.get('menuIsOpen')
);

export const selectSubMenuContents = () => createSelector(
	selectApp(),
	appState => appState.get('secondaryMenuContents')
);

export const selectSubMenuDefault = () => createSelector(
	selectApp(),
	appState => appState.get('secondaryMenuDefault')
);

export const selectLocationState = () => {
	let prevRoutingState;
	let prevRoutingStateJS;

	return (state) => {
		const routingState = state.get('route'); // or state.route

		if (!routingState.equals(prevRoutingState)) {
			prevRoutingState = routingState;
			prevRoutingStateJS = routingState.toJS();
		}

		return prevRoutingStateJS;
	};
};

/* --- Cart ----------------------------------------------------------------- */

export const selectCart = () => createSelector(
	selectApp(),
	(appState) => appState.get('cart')
);

export const selectCartLoading = () => createSelector(
	selectApp(),
	(appState) => appState.get('cartLoading')
);

export const selectCartLoadingError = () => createSelector(
	selectApp(),
	(appState) => appState.get('cartLoadingError')
);

export const selectCartMap = () => createSelector(
	selectApp(),
	(appState) => appState.get('cartToItemMapping')
);

export const selectCartUpdating = () => createSelector(
	selectApp(),
	(appState) => appState.get('cartUpdating')
);

export const selectCartUpdateError = () => createSelector(
	selectApp(),
	(appState) => appState.get('cartUpdateError')
);

export const selectCartUpdateSuccess = () => createSelector(
	selectApp(),
	(appState) => appState.get('cartUpdateSuccess')
);

// Updating cart items
export const selectCartItemUpdating = () => createSelector(
	selectApp(),
	(appState) => appState.get('cartItemUpdating')
);

export const selectCartItemUpdateError = () => createSelector(
	selectApp(),
	(appState) => appState.get('cartItemsUpdateError')
);

export const selectCartItemUpdateSuccess = () => createSelector(
	selectApp(),
	(appState) => appState.get('cartItemsUpdateSuccess')
);

/* --- Discounts ------------------------------------------------------------ */

export const selectDiscounts = () => createSelector(
	selectApp(),
	(appState) => appState.get('discounts')
);

/**
 * Prices
 */
export const selectPrices = () => createSelector(
	selectApp(),
	(appState) => appState.get('prices')
);

/* Favorites */
export const selectFavorites = () => createSelector(
	selectApp(),
	appState => appState.get('favorites')
);

export const selectFavoritedPackageIDs = () => createSelector(
	selectApp(),
	appState => appState.get('favoritedPackageIDs')
);

export const selectFavoritesLoading = () => createSelector(
	selectApp(),
	appState => appState.get('favoritesLoading')
);

export const selectFavoritesLoadingError = () => createSelector(
	selectApp(),
	appState => appState.get('favoritesLoadingError')
);

export const selectFavoriteHolding = () => createSelector(
	selectApp(),
	appState => appState.get('favoriteHolding')
);

/**
 * Collections selectors
 */
export const selectCollection = () => createSelector(
	selectApp(),
	(appState) => appState.get('collection')
);

export const selectCollectionLoading = () => createSelector(
	selectApp(),
	(appState) => appState.get('collectionLoading')
);

export const selectCollectionIncludes = () => createSelector(
	selectApp(),
	(appState) => appState.get('collectionIncludes')
);

export const selectCollections = () => createSelector(
	selectApp(),
	(appState) => appState.get('collections')
);

export const selectAdditional = () => createSelector(
	selectApp(),
	(appState) => appState.get('additionalMenuItems')
);
export const selectAdditionalLoading = () => createSelector(
	selectApp(),
	(appState) => appState.get('additionalMenuItemsLoading')
);
export const selectAdditionalError = () => createSelector(
	selectApp(),
	(appState) => appState.get('additionalMenuItemsError')
);

export const selectSearchResults = () => createSelector(
	selectApp(),
	(state) => state.get('search').get('results').toJS()
);

export const selectAuthenticationModal = () => createSelector(
	selectApp(),
	(state) => state.get('authenticationModal')
);

/**
 * SiteHeader
 */

export const selectSiteHeaderState = () => createSelector(
	selectApp(),
	appState => appState.get('siteHeader').toJS()
);

/**
 * Promo codes
 */
export const selectPromoCode = () => createSelector(
	selectApp(),
	(state) => state.get('promoCode')
);

export const selectPromoCodeError = () => createSelector(
	selectApp(),
	(state) => state.get('promoCodeError')
);

export const selectCurrentBlogTitle = () => createSelector(
	selectBlog(),
	state => state ? state.get('currentBlogTitle') : null
);

export const selectPolicyContent = () => createSelector(
	selectApp(),
	(state) => state.get('policyContent')
);

export const selectPolicyContentError = () => createSelector(
	selectApp(),
	(state) => state.get('policyContentError')
);

// get contact text selector by alexei
export const selectContactText = () =>
	createSelector(selectApp(), (appState) => appState.get('contactText'));

export default {
	selectPolicyContent,
	selectPolicyContentError,
	selectAlert,
	selectApp,
	selectAuthenticationModal,
	selectAuthState,
	selectBreadcrumbState,
	selectBreakpoints,
	selectCart,
	selectCartLoading,
	selectCartLoadingError,
	selectCartMap,
	selectCartUpdateError,
	selectCartUpdateSuccess,
	selectCartUpdating,
	selectCollection,
	selectCollectionIncludes,
	selectCollections,
	selectCounted,
	selectError,
	selectFavoritedPackageIDs,
	selectFavoriteHolding,
	selectFavorites,
	selectFavoritesLoading,
	selectFavoritesLoadingError,
	selectFooter,
	selectIsMenuOpen,
	selectLastAdditionalMenuFetched,
	selectLicenseBarLocation,
	selectLocationState,
	selectMenu,
	selectMenuContents,
	selectMobileState,
	selectOverlayState,
	selectPreloadedSubMenus,
	selectPreloadedSubMenusLoaded,
	selectPrices,
	selectPromoCode,
	selectPromoCodeError,
	selectSearchResults,
	selectSessionID,
	selectSiteHeaderState,
	selectSubMenu,
	selectSubMenuContents,
	selectSubMenuSnapshot,
	selectTertiaryMenu,
	selectCurrentBlogTitle,
	selectContactText
};
