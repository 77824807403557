/*
 * SignIn reducer
 */

import { fromJS } from 'immutable';

import { LOCATION_CHANGE } from 'react-router-redux';
import {
	LOOKUP,
	LOOKUP_ERROR,
	LOOKUP_LOADING,

	SIGNIN,
	SIGNIN_ERROR,
	SIGNIN_LOADING,

	SIGNUP,
	SIGNUP_ERROR,
	SIGNUP_LOADING
} from './constants';

export const initialState = fromJS({
	signInError: null,
	lookupError: null,
	signUpError: null,

	isLoadingSignIn: false,
	isLoadingLookup: false,
	isLoadingSignUp: false
});

export default function authenticationReducer(state = initialState, action) {
	switch (action.type) {
		case LOOKUP:
			return state
				.set('isLoadingLookup', false)
				.set('lookupError', null);

		case LOOKUP_LOADING:
			return state
				.set('isLoadingLookup', action.data);

		case LOOKUP_ERROR:
			return state
				.set('lookupError', action.error);


		case SIGNIN:
			return state
				.set('isLoadingSignIn', false)
				.set('signInError', null);

		case SIGNIN_LOADING:
			return state
				.set('isLoadingSignIn', action.data);

		case SIGNIN_ERROR:
			return state
				.set('signInError', action.error);


		case SIGNUP:
			return state
				.set('isLoadingSignUp', false)
				.set('signUpError', null);

		case SIGNUP_LOADING:
			return state
				.set('isLoadingSignUp', action.data);

		case SIGNUP_ERROR:
			return state
				.set('signUpError', action.error);


		case LOCATION_CHANGE:
			return initialState;

		default:
			return state;
	}
}
