/*
 * Account reducer
 */

import { fromJS } from 'immutable';
import { get, filter, isEmpty } from 'lodash';
import camelcase from 'camelize';

import {
	CLEAR,
	CLEAR_USER,

	SET_COUNTRIES,
	SET_USER_CREDIT_CARD,
	SET_ERROR,
	SET_LOADING,
	SET_EMAIL_PREFERENCE,
	SET_USER_BILLING,
	SET_USER_MAILING,
	SET_USER_PROFILE,

	SET_SELF_HOSTED_UPGRADE_OVERVIEW,
	SET_SELF_HOSTED_RENEWAL_OVERVIEW,
	SET_SELF_HOSTED_PAGEVIEWS,

	SET_ACCOUNT_DELETION_VERIFICATION
} from './constants';

export const initialState = fromJS({
	error: {},
	loading: {},

	countries: null,
	user: null,
	billing: null,
	mailing: null,

	selfHosted: {
		upgrade: {},
		renewal: {},
		pageviews: null
	},

	accountDeletionVerification: null
});

function getCreditCardName(type) {
	switch (type) {
		case 'VI': return 'Visa';
		case 'AX': return 'American Express';
		case 'MC': return 'MasterCard';
		default: return 'Other';
	}
}

function getCreditCard(getActionData) {
	return {
		number: getActionData('attributes.credit_card_info.mask_number'),
		type: getCreditCardName(getActionData('attributes.credit_card_info.card_type')),
		expirationMonth: '••',
		expirationYear: '••••',
		hasExpired: getActionData('attributes.has_expired_credit_card')
	};
}

export default function accountReducer(state = initialState, action) {
	const getActionData = (path) => get(action.data, path);

	switch (action.type) {
		case CLEAR:
			return state
				.mergeDeep({
					loading: { [action.data.type]: false },
					error: { [action.data.type]: false }
				});

		case CLEAR_USER:
			return state
				.set('user', null)
				.set('billing', null)
				.set('mailing', null);

		case SET_LOADING:
			return state
				.mergeDeep({ loading: { [action.data.type]: action.data.loading } });

		case SET_ERROR:
			return state
				.mergeDeep({ error: { [action.data.type]: action.data.error } });

		case SET_COUNTRIES:
			return state
				.set('countries', action.data.map(i => ({
					id: i.id,
					name: i.attributes.short_name,
					sequence: i.attributes.sequence
				})));

		case SET_USER_CREDIT_CARD:
			return state
				.mergeDeep({
					billing: {
						creditCard: !action.data ? null : getCreditCard(getActionData)
					}
				});

		case SET_EMAIL_PREFERENCE:
			return state
				.mergeDeep({
					user: {
						newsletter: action.data
					}
				});

		case SET_USER_PROFILE: {
			const subscriptions = {
				cloud: false,
				app: false,
				fonts: false,
				orderHistory: false
			};

			const computerLicensesData = getActionData('relationships.computer_licenses.data');
			const webfontLicensesData = getActionData('relationships.webfont_licenses.data');
			const selfHostedLicensesData = getActionData('relationships.self_hosted_licenses.data');
			const hasOrders = getActionData('attributes.has_purchased_orders');
			const includedData = getActionData('included');

			subscriptions.orderHistory = hasOrders;
			if (getActionData('relationships')) {
				subscriptions.cloud = !!getActionData('relationships.cloud_subscription.data.id')
					|| get(state, 'user.subscriptions.cloud')
					|| false;

				subscriptions.app = !!getActionData('relationships.app_subscription.data.id')
					|| get(state, 'user.subscriptions.app')
					|| false;

				subscriptions.fonts = (computerLicensesData && computerLicensesData.length > 0)
					|| (webfontLicensesData && webfontLicensesData.length > 0)
					|| (selfHostedLicensesData && selfHostedLicensesData.length > 0)
					|| get(state, 'user.subscriptions.fonts')
					|| false;
			}

			subscriptions.cloudExpired = getActionData('attributes.expired_cloud_subscription_id');
			subscriptions.cloudAutoRenew = getActionData('attributes.cloud_auto_renew') === 1;

			if (includedData && includedData.length > 0) {
				subscriptions.usageSnapshots = includedData.some(element => element.type === 'monthly_usage_snapshots');
			}

			return state
				.mergeDeep({
					user: {
						id: getActionData('id'),

						firstName: getActionData('attributes.first_name'),
						name: getActionData('attributes.full_name'),
						email: getActionData('attributes.email_address'),
						company: getActionData('attributes.bill_company'),
						publication: getActionData('attributes.publication'),
						website: getActionData('attributes.website'),
						taxId: getActionData('attributes.tax_id'),
						freeFonts: getActionData('attributes.available_free_fonts'),
						newsletter: getActionData('attributes.newsletter_subscriber'),
						addressSame: getActionData('attributes.address_same'),
						favoritesCount: getActionData('attributes.favorites_count'),
						phone: getActionData('attributes.phone_no'),

						subscriptions
					}
				});
		}

		case SET_USER_BILLING: {
			const hasCreditCard = !!getActionData('attributes.credit_card_info.mask_number');

			// Ensure the mailing address checkbox is checked when it should be
			let isMailingAddressSame;
			if (isEmpty(getActionData('attributes.bill_country')) && isEmpty(getActionData('attributes.bill_address1'))) {
				isMailingAddressSame = true;
			} else { isMailingAddressSame = getActionData('attributes.address_same', '').toString() === '1'; }

			return state
				.mergeDeep({
					billing: {
						company: getActionData('attributes.bill_company'),
						publication: getActionData('attributes.publication'),
						address1: getActionData('attributes.bill_address1'),
						address2: getActionData('attributes.bill_address2'),
						address3: getActionData('attributes.bill_address3'),
						city: getActionData('attributes.bill_city'),
						state: getActionData('attributes.bill_state'),
						zip: getActionData('attributes.bill_zip'),
						country: getActionData('attributes.bill_country'),
						phone: getActionData('attributes.phone_no'),

						isMailingAddressSame,

						creditCard: !hasCreditCard ? null : getCreditCard(getActionData)
					}
				});
		}

		case SET_USER_MAILING:
			return state
				.mergeDeep({
					mailing: {
						address1: getActionData('attributes.ship_address1'),
						address2: getActionData('attributes.ship_address2'),
						address3: getActionData('attributes.ship_address3'),
						city: getActionData('attributes.ship_city'),
						state: getActionData('attributes.ship_state'),
						zip: getActionData('attributes.ship_zip'),
						country: getActionData('attributes.ship_country')
					}
				});

		case SET_SELF_HOSTED_UPGRADE_OVERVIEW:
			return state
				.setIn(['selfHosted', 'upgrade'], fromJS(camelcase(action.data)));

		case SET_SELF_HOSTED_RENEWAL_OVERVIEW:
			return state
				.setIn(['selfHosted', 'renewal'], fromJS(camelcase(action.data)));

		case SET_SELF_HOSTED_PAGEVIEWS: {
			const data = camelcase(action.data);
			const pageviews = {
				annual: filter(data, { planType: 'annual' }),
				perpetual: filter(data, { planType: 'perpetual' })
			};
			return state
				.setIn(['selfHosted', 'pageviews'], fromJS(pageviews));
		}

		case SET_ACCOUNT_DELETION_VERIFICATION: {
			return state.set('accountDeletionVerification', action.data);
		}

		default:
			return state;
	}
}
