import { LOCATION_CHANGE } from 'react-router-redux';
import { fromJS } from 'immutable';
import { get, isEqual, transform } from 'lodash';
import camelcase from 'camelize';
import pluralize from 'pluralize';
import numeral from 'numeral';

// Config items
import { cart as cartConfig } from 'config';
import { generateDescription, generateLicensePhrase } from 'utils';

/*
 * AppReducer
 */
import {
	TOGGLE_FAVORITE_ERROR,
	TOGGLE_FAVORITE_HOLDING,
	TOGGLE_FAVORITE_SUCCESS,
	TOGGLE_FAVORITE,
	ADD_TO_CART,
	CART_UPDATE_ERROR,
	CART_UPDATE_SUCCESS,
	CLEAR_ADDITIONAL_MENU_ITEMS,
	CLEAR_ALERT,
	CLEAR_CART,
	CLEAR_COLLECTION,
	CLEAR_ERROR,
	CLEAR_FAVORITES,
	CLEAR_MENU_CONTENTS,
	CLEAR_MENU,
	CLEAR_PRICES,
	CLEAR_PROMO_CODE,
	CLEAR_SEARCH,
	CLEAR_SITE_HEADER_STATE,
	CLEAR_SUB_MENU_CONTENTS,
	CLEAR_SUB_MENU_SNAPSHOT,
	CLEAR_SUB_MENU,
	CLOSE_AUTHENTICATION_MODAL,
	GET_CART_ERROR,
	GET_CART_SUCCESS,
	GET_CART,
	GET_PRICES_ERROR,
	GET_PRICES_SUCCESS,
	GET_PRICES,
	LICENSE_BAR_LOCATION,
	LOAD_ADDITIONAL_MENU_ITEMS_ERROR,
	LOAD_ADDITIONAL_MENU_ITEMS_SUCCESS,
	LOAD_ADDITIONAL_MENU_ITEMS,
	LOAD_COLLECTION_ERROR,
	LOAD_COLLECTION_SUCCESS,
	LOAD_COLLECTION,
	LOAD_COLLECTIONS_ERROR,
	LOAD_COLLECTIONS_SUCCESS,
	LOAD_COLLECTIONS,
	LOAD_FAVORITES_ERROR,
	LOAD_FAVORITES_SUCCESS,
	LOAD_FAVORITES,
	LOAD_FIND_BY_NAME_ERROR,
	LOAD_FIND_BY_NAME_SUCCESS,
	LOAD_FIND_BY_NAME,
	LOAD_TYPE_CAPSULES_ERROR,
	LOAD_TYPE_CAPSULES_SUCCESS,
	LOAD_TYPE_CAPSULES,
	LOAD_HOME,
	LOAD_HOME_SUCCESS,
	LOAD_HOME_ERROR,
	LOAD_MENU_CONTENTS_ERROR,
	LOAD_MENU_CONTENTS_SUCCESS,
	LOAD_MENU_CONTENTS,
	LOAD_SEARCH_ERROR,
	LOAD_SEARCH_SUCCESS,
	LOAD_SEARCH,
	LOAD_SUB_MENU_CONTENTS_ERROR,
	LOAD_SUB_MENU_CONTENTS_SUCCESS,
	LOAD_SUB_MENU_CONTENTS,
	MENU_IS_OPEN,
	OPEN_AUTHENTICATION_MODAL,
	PRELOAD_SUBMENUS_ERROR,
	PRELOAD_SUBMENUS_SUCCESS,
	PRELOAD_SUBMENUS,
	REMOVE_FAVORITE_HOLDING,
	REMOVE_FROM_CART,
	SET_ACTIVE_BREAKPOINTS,
	SET_ALERT,
	SET_AUTH_STATE,
	SET_BREADCRUMB,
	SET_COLLECTION,
	SET_ERROR,
	SET_MENU,
	SET_OVERLAY_VISIBILITY,
	SET_PROMO_CODE_ERROR,
	SET_PROMO_CODE_SUCCESS,
	SET_SITE_HEADER_STATE,
	SET_SUB_MENU_SNAPSHOT,
	SET_SUB_MENU,
	SET_TERTIARY_MENU,
	TOGGLE_MOBILE_STATE,
	UPDATE_CART_ITEMS_ERROR,
	UPDATE_CART_ITEMS_SUCCESS,
	UPDATE_CART_ITEMS,
	SET_POLICY_TEXT_SUCCESS,
	SET_POLICY_TEXT_ERROR,
	SET_POLICY_TEXT_LOADING,
	LOAD_CONTACT_TEXT_DISPLAY_SUCCESS,
	LOAD_CONTACT_TEXT_DISPLAY_ERROR
} from './constants';

// The initial state of the App
const initialState = fromJS({
	// Alert
	alert: null,
	contactText: '',
	breadcrumb: {
		menu: {
			parent: null,
			current: {
				label: 'H&Co',
				path: '/'
			},
			children: null
		},
		page: {
			parent: null,
			current: {
				label: 'H&Co',
				path: '/'
			},
			children: null
		}
	},
	breakpoints: {},
	error: {},

	// Cart
	cart: null,
	cartLoading: false,
	cartLoadingError: false,

	// Details for each cart item, pulled from the item's source package or product.
	cartDetails: null,
	cartDetailsLoading: false,
	cartDetailsLoadingError: null,
	cartDetailsLoadingSuccess: true,

	// Cart items (cloud and app subscriptions, desktop fonts, web fonts)
	cartToItemMapping: null, // Mapping of cart_item_ID -> item_id (Example: Object {200368: "687660", 200369: "687663", 200371: "687664"})
	cartUpdating: false,
	cartUpdateError: null,
	cartUpdateSuccess: false,
	cartItemUpdating: null,
	cartItemsUpdateError: null,
	cartItemsUpdateSuccess: false,

	// Collections
	collection: null,
	collectionIncludes: null,
	collectionLoading: false,
	collectionError: false,
	collections: null,
	collectionsLoading: false,
	collectionsError: false,

	additionalMenuItemsFetched: null,
	additionalMenuItems: null,
	additionalMenuItemsError: false,
	additionalMenuItemsLoading: false,

	// Preloaded Submenus
	preloadSubMenuLoading: false,
	preloadSubMenuSuccess: false,
	preloadSubMenuError: false,
	preloadedSubMenu: {
		collections: null,
		findByName: null,
		typeCapsules: null,
		additional: null
	},

	promoCode: null,
	promoCodeError: false,

	// Preload Status of findByName Fonts SubMenu
	findByNameLoading: false,
	findByNameSuccess: false,
	findByNameError: false,

	// Preload Status of typeCapsules Fonts SubMenu
	typeCapsulesLoading: false,
	typeCapsulesSuccess: false,
	typeCapsulesError: false,

	// Discounts
	discounts: null,

	favorites: null,
	favoritedPackageIDs: null,
	favoritesLoading: true,
	favoritesLoadingError: false,

	toggleFavorite: false,
	toggleFavoriteSuccess: false,
	toggleFavoriteError: false,

	favoriteHolding: null,

	isAuthenticated: false,
	isMobile: undefined,

	// Location of LicenseBar. Value should be 'hidden', 'static' or 'fixed'.
	licenseBarLocation: 'hidden',

	// Site menu
	menu: '',
	menuContents: {},
	menuContentsLoading: false,
	menuContentsLoadingError: false,
	menuIsOpen: false,

	secondaryMenu: '',
	secondaryMenuSnapshot: null,
	secondaryMenuContents: null,
	secondaryMenuContentsLoading: false,
	secondaryMenuContentsLoadingError: false,
	secondaryMenuDefault: null,

	// Global site overlay (primarily for modals)
	overlayIsVisible: false,

	prices: null,

	selectedTertiaryMenu: null,

	sessionID: null,
	sessionIDError: null,

	// SiteHeader
	siteHeader: {},

	// User data
	user: null,
	userLoading: true,
	userError: null,
	userSuccess: false,

	withFooter: false,
	search: {
		results: {
			menu: null,
			page: null
		},
		loading: null,
		error: false
	},
	authenticationModal: null,
	policyContent: null,
	policyContentError: null
});


function generatePhrase(cart, item) {
	switch (item.licenseType) {
		case 'cloud_license': {
			const count = numeral(item.cloudSubscriptionPageview).format('0,0');
			return `Includes ${count} pv/mo`;
		}
		case 'app_license':
			// return 'Coming soon.';
			return `${item.appTitleCount} ${pluralize('title', item.appTitleCount)} for ${item.quantity} ${pluralize('year', item.quantity)}`;

		default:
			return generateLicensePhrase({
				computer: item.quantity,
				cloud: cart.hasCloud,
				app: cart.hasApp
			});
	}
}

function appReducer(state = initialState, action) {
	switch (action.type) {
		case SET_AUTH_STATE: {
			return state
				.set('isAuthenticated', action.isAuthenticated);
		}

		// Menu
		case SET_TERTIARY_MENU: {
			const currentMenu = state.get('selectedTertiaryMenu');
			const nextMenu = !isEqual(currentMenu, fromJS(action.menu)) ? action.menu : null;
			return state
				.mergeDeep({ selectedTertiaryMenu: nextMenu });
		}

		// Breadcrumb data
		case SET_BREADCRUMB: {
			return state
				.mergeDeepIn(['breadcrumb', action.breadcrumbBarType], action.data);
		}

		// Dark overlay
		case SET_OVERLAY_VISIBILITY: {
			return state
				.mergeDeep({ overlayIsVisible: action.isVisible });
		}

		// Are we on a mobile device?
		case TOGGLE_MOBILE_STATE:
			return state
				.set('isMobile', action.isMobile);

		// Toggle LicenseBar location
		case LICENSE_BAR_LOCATION:
			return state
				.set('licenseBarLocation', action.location);


		// Breakpoints
		case SET_ACTIVE_BREAKPOINTS:
			return state
				.mergeDeep({ breakpoints: action.data });

		// Errors
		case CLEAR_ERROR:
			return state
				.set('error', false);

		case SET_ERROR:
			return state
				.set('error', action.payload);

		// Alert
		case CLEAR_ALERT:
			return state
				.set('alert', null);

		case SET_ALERT:
			return state
				.set('alert', action.payload);

		// Site menu
		case CLEAR_MENU:
			return state
				.set('menu', null);

		case SET_MENU: {
			if (state.get('menu') === action.data) {
				return state
					.set('menu', null)
					.set('menuContents', null)
					.set('secondaryMenu', null)
					.set('secondaryMenuContents', null);
			}

			return state
				.set('menu', action.data);
		}

		case CLEAR_SUB_MENU:
			return state
				.set('secondaryMenu', null)
				.set('secondaryMenuSnapshot', null);

		case MENU_IS_OPEN:
			return state
				.set('menuIsOpen', action.data);

		case SET_SUB_MENU:
			if (['collections', 'findByName', 'typeCapsules'].includes(action.data)) {
				return state
					.set('counted', action.data.counted)
					.set('secondaryMenu', action.data.menu)
					.set('secondaryMenuDefault', action.data.menu);
			}
			return state
				.set('counted', action.data.counted)
				.set('secondaryMenu', action.data.menu);

		case SET_SUB_MENU_SNAPSHOT: {
			const previousSnapshot = state.get('secondaryMenuSnapshot');

			if (action.data === previousSnapshot) { return state; }
			return state
				.set('secondaryMenuSnapshot', action.data);
		}

		case CLEAR_SUB_MENU_SNAPSHOT:
			return state
				.set('secondaryMenuSnapshot', null);

		case LOCATION_CHANGE:
			return state
				.set('error', false);

		// Get cart
		case GET_CART:
			return state
				.set('cartLoading', true)
				.set('cartLoadingError', false)
				.set('cartLoadingSuccess', false);

		case GET_CART_ERROR:
			return state
				.set('cartLoading', false)
				.set('cartLoadingError', action.error)
				.set('cartLoadingSuccess', false);

		// Cart retrieval success
		case GET_CART_SUCCESS: {
			const data = camelcase(action.data);
			const cart = get(data, 'cartItems.cartItems', []);
			const discounts = get(data, 'discounts.discounts', []);
			const licensesInCart = cart.map(i => cartConfig.types[i.licenseType].name);
			const promoCode = get(data, 'promoCodes.promoCodes.0');

			// Converting cart results to hashmap with key (item_id) : value (cart_item_id) pairs.
			// Example: Object {200368: "687660", 200369: "687663", 200371: "687664"}
			const cartToItemMapping = transform(cart, (memo, value) => (memo[value.item.id] = value.id), {}); // eslint-disable-line no-param-reassign,no-return-assign
			const cartDiscounts = discounts.map(i => ({
				id: i.id,
				name: i.name,
				total: i.total
			}));

			const cartObject = {
				ctas: data.ctas.ctas,
				discountTotal: data.discountTotal,
				has_app: data.hasApp,
				has_cloud: data.hasCloud,
				has_subscription: data.hasCloud || data.hasApp,
				hasPromoCode: !!promoCode,
				id: data.id,
				items: cart.map((item) => ({
					appTitleCount: item.appTitleCount,
					cartRecordID: item.id,
					cloudOnlyPrice: item.item.cloudOnlyPrice,
					cloudSubscriptionID: parseInt(item.cloudSubscriptionId, 10),
					description: generateDescription(cartConfig.types[item.licenseType].name, item.quantity, licensesInCart),
					freeCloudFont: item.freeCloudFont > 0,
					id: item.id,
					itemID: item.item.id,
					licenseType: item.licenseType,
					name: item.item.name,
					oldPrice: item.item.oldPrice,
					phrase: generatePhrase(data, item),
					price: item.price,
					quantity: item.quantity,
					removed: item.removed,
					selfHostedID: item.selfHostedPageviewId,
					urlSlug: item.item.urlSlug
				})),
				itemsInCart: data.itemsInCart,
				promoCode,
				promoCodeDiscountTotal: data.promoCodeDiscountTotal,
				subtotal: data.subtotal,
				tax_total: data.taxTotal,
				total: data.total
			};

			return state
				.set('cartLoading', false)
				.set('cartLoadingError', false)
				.set('cartLoadingSuccess', true)
				.set('cartToItemMapping', cartToItemMapping)
				.set('discounts', cartDiscounts)
				.set('cart', cartObject)
				.set('promoCode', promoCode);
		}

		case CLEAR_CART:
			return state
				.set('cart', null)
				.set('cartToItemMapping', {});

		// Add item to cart
		case ADD_TO_CART:
			return state
				.set('cartUpdating', true)
				.set('cartUpdateError', null)
				.set('cartUpdateSuccess', false);

		// Remove item from cart
		case REMOVE_FROM_CART:
			return state
				.set('cartUpdating', true)
				.set('cartUpdateError', null)
				.set('cartUpdateSuccess', false);

		// Cart item successfully added
		case CART_UPDATE_SUCCESS:
			return state
				.set('cartUpdating', false)
				.set('cartUpdateError', null)
				.set('cartUpdateSuccess', true);

		// Cart item error
		case CART_UPDATE_ERROR:
			return state
				.set('cartUpdating', false)
				.set('cartUpdateError', action.error)
				.set('cartUpdateSuccess', false);

		// Cart item updating
		case UPDATE_CART_ITEMS:
			return state
				.set('cartItemUpdating', action.data.ids)
				.set('cartItemsUpdateError', null)
				.set('cartItemsUpdateSuccess', false);

		case UPDATE_CART_ITEMS_SUCCESS:
			return state
				.set('cartItemUpdating', null)
				.set('cartItemsUpdateError', null)
				.set('cartItemsUpdateSuccess', true);

		// Cart item update error
		case UPDATE_CART_ITEMS_ERROR:
			return state
				.set('cartItemUpdating', null)
				.set('cartItemsUpdateError', action.error)
				.set('cartItemsUpdateSuccess', false);

		// Load prices
		case GET_PRICES:
			return state
				.set('pricesLoading', true)
				.set('error', false)
				.set('prices', null);

		case GET_PRICES_SUCCESS:
			return state
				.set('prices', action.data)
				.set('pricesLoading', false);

		case GET_PRICES_ERROR:
			return state
				.set('error', action.error)
				.set('pricesLoading', false);

		// Clear prices
		case CLEAR_PRICES:
			return state
				.set('prices', null);


		// Home Page
		case LOAD_HOME:
			return state
				.set('homepageLoading', true)
				.set('homepageLoadingError', false);

		case LOAD_HOME_SUCCESS: {
			const data = camelcase(action.data);
			const homepage = data.cmsEntries[0];

			const options = homepage.content.options;

			return state
				.set('homepage', action.data)
				.set('homepageError', false)
				.set('homepageLoading', false);
		}

		case LOAD_HOME_ERROR:
			return state
				.set('homepage', action.data)
				.set('homepageLoadingError', action.error)
				.set('homepageLoading', false);


		// Favorites
		case LOAD_FAVORITES:
			return state
				.set('favoritesLoading', true)
				.set('favoritesLoadingError', false);

		case LOAD_FAVORITES_SUCCESS: {
			const data = camelcase(action.data);
			const source = get(data, 'wishlistItems');

			// Save just the IDs for simpler reference in favoritable components
			const favoritedPackageIDs = source.map((item) => get(item, 'itemId'));

			const oldFavorites = state.get('favorites');
			const favorites = source.map((favorite) => {
				if (!favorite.items && oldFavorites) {
					const oldFavorite = find(oldFavorites, { id: favorite.id });
					favorite.items = oldFavorite.items;
				}
				return favorite;
			});

			return state
				.set('favorites', favorites)
				.set('favoritedPackageIDs', favoritedPackageIDs)
				.set('favoritesLoadingError', false)
				.set('favoritesLoading', false);
		}

		case LOAD_FAVORITES_ERROR:
			return state
				.set('favoritesLoadingError', action.error)
				.set('favoritesLoading', false);

		case CLEAR_FAVORITES:
			return state
				.set('favorites', null);

		// set contact text display by alexei
		case LOAD_CONTACT_TEXT_DISPLAY_SUCCESS:
			return state.set('contactText', action.data);
		case LOAD_CONTACT_TEXT_DISPLAY_ERROR:
			return state.set('contactText', action.data);

		// Add Favorite
		case TOGGLE_FAVORITE:
			return state
				.set('toggleFavorite', true)
				.set('toggleFavoriteSuccess', false)
				.set('toggleFavoriteError', false);

		case TOGGLE_FAVORITE_SUCCESS:
			return state
				.set('toggleFavorite', false)
				.set('toggleFavoriteSuccess', true)
				.set('toggleFavoriteError', false);

		case TOGGLE_FAVORITE_ERROR:
			return state
				.set('toggleFavorite', false)
				.set('toggleFavoriteSuccess', false)
				.set('favoritesLoadingError', action.error);

		case TOGGLE_FAVORITE_HOLDING:
			return state
				.set('favoriteHolding', action.data);

		case REMOVE_FAVORITE_HOLDING:
			return state
				.set('favoriteHolding', null);

		// Set SiteHeader state
		case SET_SITE_HEADER_STATE:
			return state
				.mergeDeep({ siteHeader: action.data });

		case CLEAR_SITE_HEADER_STATE:
			return state
				.set('siteHeader', fromJS({}));

		// Collections
		case SET_COLLECTION:
			return state
				.set('collection', action.data);

		case LOAD_COLLECTIONS:
			return state
				.set('collectionsLoading', true)
				.set('collectionsError', false)
				.set('collections', null);

		case LOAD_COLLECTIONS_SUCCESS:
			return state
				.set('collections', action.data.sections)
				.setIn(['preloadedSubMenu', 'collections'], action.data)
				.set('collectionsLoading', false);

		case LOAD_COLLECTIONS_ERROR:
			return state
				.set('collectionsError', action.error)
				.set('collectionsLoading', false);

		case LOAD_COLLECTION:
			return state
				.set('collectionLoading', true)
				.set('collectionError', false);

		case LOAD_COLLECTION_SUCCESS:
			return state
				.set('collection', action.data)
				.set('collectionIncludes', action.data.styles.styles)
				.set('collectionLoading', false);

		case LOAD_COLLECTION_ERROR:
			return state
				.set('collectionError', action.error)
				.set('collectionLoading', false);

		case CLEAR_COLLECTION:
			return state
				.set('collection', null)
				.set('collectionIncludes', null)
				.set('collectionLoading', false);

		case LOAD_FIND_BY_NAME:
			return state
				.set('findByNameLoading', true)
				.set('findByNameSuccess', false)
				.set('findByNameError', false);

		case LOAD_FIND_BY_NAME_SUCCESS:
			return state
				.set('findByNameLoading', false)
				.set('findByNameSuccess', true)
				.set('findByNameError', false)
				.setIn(['preloadedSubMenu', 'findByName'], action.data);

		case LOAD_FIND_BY_NAME_ERROR:
			return state
				.set('findByNameLoading', false)
				.set('findByNameSuccess', false)
				.set('findByNameError', action.error);

		case LOAD_TYPE_CAPSULES:
			return state
				.set('typeCapsulesLoading', true)
				.set('typeCapsulesSuccess', false)
				.set('typeCapsulesError', false);

		case LOAD_TYPE_CAPSULES_SUCCESS:
			return state
				.set('typeCapsulesLoading', false)
				.set('typeCapsulesSuccess', true)
				.set('typeCapsulesError', false)
				.setIn(['preloadedSubMenu', 'typeCapsules'], action.data);

		case LOAD_TYPE_CAPSULES_ERROR:
			return state
				.set('typeCapsulesLoading', false)
				.set('typeCapsulesSuccess', false)
				.set('typeCapsulesError', action.error);

		case LOAD_ADDITIONAL_MENU_ITEMS:
			return state
				.set('additionalMenuItemsFetched', action.section)
				.set('additionalMenuItemsLoading', true)
				.set('additionalMenuItemsError', false)
				.set('additionalMenuItems', null);

		case LOAD_ADDITIONAL_MENU_ITEMS_SUCCESS: {
			const data = camelcase(action.data.data);

			// Format response in a way that’s accepted by additional contents
			// found with the browse by styles font menu along with collection
			// container page.
			const items = data.map((item) => ({
				attributes: item.attributes,
				collection_name: item.attributes.collectionName,
				id: item.id,
				label: item.attributes.collectionName,
				slug: item.attributes.urlSlug,
				url_slug: item.attributes.urlSlug
			}));

			const { preloadedSubMenu } = state.toJS();
			// Future proofing: if there are other additional menus in the
			// "preloadedSubMenu.additional" object then grab those. If not,
			// start with a fresh object. This is done because in the future, findByName
			// may also have an additional menu and we would like to cache
			// it in redux store. Currently the additional menu store gets
			// completely overwritten with each new API call, which is ok for now
			// since there is only one additional items menu (associated with
			// "Browse by Style"), in the future, if there are others, the
			// collection container page will not display properly for such items
			// as "Children's Books" since this data point gets overwritten.
			// Caching the data in preloadedSubMenu prevents this.
			const updatedAdditional =
				(preloadedSubMenu.additional)
					? { ...preloadedSubMenu.additional }
					: {};

			// Injected the additional menu update data (e.g. additional for
			// collections) into additional object
			updatedAdditional[action.data.section] = items;

			return state
				.set('additionalMenuItems', items)
				.setIn(['preloadedSubMenu', 'additional'], updatedAdditional)
				.set('additionalMenuItemsLoading', false);
		}

		case LOAD_ADDITIONAL_MENU_ITEMS_ERROR:
			return state
				.set('additionalMenuItemsError', action.error)
				.set('additionalMenuItemsLoading', false);


		case CLEAR_ADDITIONAL_MENU_ITEMS:
			return state
				.set('additionalMenuItems', null)
				.set('additionalMenuItemsError', false)
				.set('additionalMenuItemsLoading', false);

		case CLEAR_MENU_CONTENTS:
			return state
				.set('menuContents', {})
				.set('menuContentsLoading', false)
				.set('menuContentsLoadingError', false);

		case LOAD_MENU_CONTENTS:
			return state
				.set('menuContents', {})
				.set('menuContentsLoading', true)
				.set('menuContentsLoadingError', false);

		case LOAD_MENU_CONTENTS_ERROR:
			return state
				.set('menuContents', {})
				.set('menuContentsLoading', false)
				.set('menuContentsLoadingError', action.error);

		case LOAD_MENU_CONTENTS_SUCCESS: {
			const data = camelcase(action.data);
			let sections = null;

			// Data came from the API. Let's make sure it adheres to the format
			// the menu expects.
			if (data && data.source !== 'local') {
				sections = data.sections.map((section) => ({
					section: section.collectionType,
					icon: section.iconName,
					link: `/fonts/styles/${section.urlSlug}`,
					slug: section.urlSlug,
					text: section.collectionName
				}));
				data.sections = sections;
			}

			return state
				.set('menuContents', data)
				.set('menuContentsLoading', false)
				.set('menuContentsLoadingError', false);
		}

		case CLEAR_SUB_MENU_CONTENTS:
			return state
				.set('secondaryMenuContents', null)
				.set('secondaryMenuContentsLoading', false)
				.set('secondaryMenuContentsLoadingError', false);

		case LOAD_SUB_MENU_CONTENTS: {
			return state
				.set('secondaryMenuContents', null)
				.set('secondaryMenuContentsLoading', true)
				.set('secondaryMenuContentsLoadingError', false);
		}

		case LOAD_SUB_MENU_CONTENTS_ERROR:
			return state
				.set('secondaryMenuContents', null)
				.set('secondaryMenuContentsLoading', false)
				.set('secondaryMenuContentsLoadingError', action.error);

		case LOAD_SUB_MENU_CONTENTS_SUCCESS: {
			const data = camelcase(action.data);
			const menuContents = data;
			let sections = null;

			// Data came from the API. Let's make sure it adheres to the format
			// the menu expects.
			if (data && data.source !== 'local') {
				sections = data.sections.map((section) => ({
					additional: section.additional,
					icon: section.iconName,
					id: section.id,
					link: `/fonts/styles/${section.urlSlug}`,
					section: section.collectionType,
					slug: section.urlSlug,
					text: section.collectionName,
					visible: section.visible
				}));
				menuContents.sections = sections;
			}

			return state
				.set('secondaryMenuContents', menuContents)
				.set('secondaryMenuContentsLoading', false)
				.set('secondaryMenuContentsLoadingError', false);
		}

		case LOAD_SEARCH:
			return state
				.setIn(['search', 'loading'], true)
				.setIn(['search', 'error'], false);
		case LOAD_SEARCH_SUCCESS:
			return state
				.setIn(['search', 'results', action.layout], fromJS(camelcase(action.data)))
				.setIn(['search', 'loading'], true)
				.setIn(['search', 'error'], false);
		case LOAD_SEARCH_ERROR:
			return state
				.setIn(['search', 'results', action.layout], null)
				.setIn(['search', 'loading'], false)
				.setIn(['search', 'error'], action.error);
		case CLEAR_SEARCH:
			return state
				.setIn(['search', 'results', action.layout], null);

		case OPEN_AUTHENTICATION_MODAL:
			return state
				.set('authenticationModal', action.data);

		case CLOSE_AUTHENTICATION_MODAL:
			return state
				.set('authenticationModal', null);

		case PRELOAD_SUBMENUS:
			return state
				.set('preloadSubMenuLoading', true)
				.set('preloadSubMenuSuccess', false)
				.set('preloadSubMenuError', false);

		case PRELOAD_SUBMENUS_SUCCESS:
			return state
				.set('preloadSubMenuLoading', false)
				.set('preloadSubMenuSuccess', true)
				.set('preloadSubMenuError', false);

		case PRELOAD_SUBMENUS_ERROR:
			return state
				.set('preloadSubMenuLoading', false)
				.set('preloadSubMenuSuccess', false)
				.set('preloadSubMenuError', action.error);

		case SET_PROMO_CODE_ERROR:
			return state
				.set('promoCodeError', action.data);

		case SET_PROMO_CODE_SUCCESS:
			return state
				.set('promoCodeError', false)
				.set('promoCode', action.data);

		case CLEAR_PROMO_CODE:
			return state
				.set('promoCode', null)
				.set('promoCodeError', false);
		case SET_POLICY_TEXT_LOADING:
			return state
				.set('policyContent', null)
				.set('policyContentError', null);
		case SET_POLICY_TEXT_SUCCESS:
			return state
				.set('policyContent', action.data);
		case SET_POLICY_TEXT_ERROR:
			return state
				.set('policyContentError', action.error);
		default:
			return state;
	}
}

export default appReducer;
