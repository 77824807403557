import breakpointConfig from 'styles/breakpoints';

// Pulling in the matchMedia polyfill just in case
require('matchmedia-polyfill');
require('matchmedia-polyfill/matchMedia.addListener');

/**
 * Breakpoints
 *
 * Object containing the breakpoints we need in this and all child components.
 * Just add the breakpoint that you need matching the format of the existing
 * entries below and it will automatically be added to state and available to
 * all child components.
 *
 * @type {Object}
 */
export default {
	// Literal breakpoints
	xSmall: matchMedia(breakpointConfig['--bp-xsmall']),
	small: matchMedia(breakpointConfig['--bp-small']),
	smallMedium: matchMedia(breakpointConfig['--bp-smallMedium']),
	medium: matchMedia(breakpointConfig['--bp-medium']),
	largeMedium: matchMedia(breakpointConfig['--bp-largeMedium']),
	large: matchMedia(breakpointConfig['--bp-large']),
	xLarge: matchMedia(breakpointConfig['--bp-xLarge']),
	giant: matchMedia(breakpointConfig['--bp-giant']),
	// Less-than breakpoints
	ltSmall: matchMedia(breakpointConfig['--lt-bp-small']),
	ltSmallMedium: matchMedia(breakpointConfig['--lt-bp-smallMedium']),
	ltMedium: matchMedia(breakpointConfig['--lt-bp-medium']),
	ltLargeMedium: matchMedia(breakpointConfig['--lt-bp-largeMedium']),
	ltLarge: matchMedia(breakpointConfig['--lt-bp-large']),
	ltXLarge: matchMedia(breakpointConfig['--lt-bp-xLarge']),
	ltXXLarge: matchMedia(breakpointConfig['--lt-bp-xxLarge']),
	ltGiant: matchMedia(breakpointConfig['--lt-bp-giant']),
	// Greater-than breakpoints
	gtXSmall: matchMedia(breakpointConfig['--gt-bp-xSmall']),
	gtSmall: matchMedia(breakpointConfig['--gt-bp-small']),
	gtMedium: matchMedia(breakpointConfig['--gt-bp-medium']),
	gtLargeMedium: matchMedia(breakpointConfig['--gt-bp-largeMedium']),
	gtLarge: matchMedia(breakpointConfig['--gt-bp-large']),
	gtXLarge: matchMedia(breakpointConfig['--gt-bp-xLarge']),
	gtXXLarge: matchMedia(breakpointConfig['--gt-bp-xxLarge']),
	gtGiant: matchMedia(breakpointConfig['--gt-bp-giant'])
};
