import breakpoints from 'utils/breakpoints';
import settings from 'styles/settings';

export default function scrollToElement(element, smoothScroll = true, scrollOffset = 0) {
	const box = element.getBoundingClientRect();
	const body = document.body;
	const docEl = document.documentElement;
	const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
	const clientTop = docEl.clientTop || body.clientTop || 0;
	const top = Math.round(box.top + scrollTop - clientTop);

	const offset = breakpoints.gtLargeMedium.matches ? (parseInt(settings['large-toolbar-height'], 10) * 2) + scrollOffset : (parseInt(settings['default-toolbar-height'], 10) * 2) + scrollOffset;

	const scrollOptions = { top: top - offset, left: 0 };
	if (smoothScroll) { scrollOptions.behavior = 'auto'; }
	window.scroll(scrollOptions);
}
