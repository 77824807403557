/**
 * Combine all reducers in this file and export the combined reducers.
 * If we were to do this in store.js, reducers wouldn't be hot reloadable.
 */

import { combineReducers } from 'redux-immutable';
import { fromJS } from 'immutable';
import { get } from 'lodash';
import { LOCATION_CHANGE } from 'react-router-redux';

import appReducer from 'pages/App/reducer';
import accountReducer from 'store/account/reducer';
import fontsReducer from 'store/fonts/reducer';
import licencesReducer from 'store/licenses/reducer';
import authenticationReducer from 'store/authentication/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@4
 *
 */

// Initial routing state
const routeInitialState = fromJS({
	locationBeforeTransitions: null
});

/**
 * Merge route into the App state
 */
function routeReducer(state = routeInitialState, action) {
	switch (action.type) {
		case LOCATION_CHANGE: {
			// Check to see if the next path and current pathname are the same.
			// If they are, it may mean the visitor just hit the menu tab twice,
			// logging a hit for page they’re currently on.
			const isSamePage = get(action, 'payload.state.next', null) === get(action, 'payload.pathname');
			if (isSamePage) { return state; }

			return state.merge({
				locationBeforeTransitions: action.payload
			});
		}
		default:
			return state;
	}
}

/**
 * Creates the main reducer with the asynchronously loaded ones
 */
export default function createReducer(asyncReducers) {
	return combineReducers({
		route: routeReducer,
		account: accountReducer,
		fonts: fontsReducer,
		licenses: licencesReducer,
		authentication: authenticationReducer,
		app: appReducer,
		...asyncReducers
	});
}
