/*
 * App Constants
 */

export const CLEAR = 'hco/App/CLEAR';
export const SET_LOADING = 'hco/App/SET_LOADING';

// Site Menu
export const SET_MENU = 'hco/App/SET_MENU';
export const CLEAR_MENU = 'hco/App/CLEAR_MENU';
export const CLEAR_MENU_CONTENTS = 'hco/App/CLEAR_MENU_CONTENTS';
export const LOAD_MENU_CONTENTS = 'hco/App/LOAD_MENU_CONTENTS';
export const LOAD_MENU_CONTENTS_SUCCESS = 'hco/App/LOAD_MENU_CONTENTS_SUCCESS';
export const LOAD_MENU_CONTENTS_ERROR = 'hco/App/LOAD_MENU_CONTENTS_ERROR';

export const SET_SUB_MENU = 'hco/App/SET_SUB_MENU';
export const SET_SUB_MENU_SNAPSHOT = 'hco/App/SET_SUB_MENU_SNAPSHOT';
export const CLEAR_SUB_MENU_SNAPSHOT = 'hco/App/CLEAR_SUB_MENU_SNAPSHOT';
export const CLEAR_SUB_MENU = 'hco/App/CLEAR_SUB_MENU';
export const CLEAR_SUB_MENU_CONTENTS = 'hco/App/CLEAR_SUB_MENU_CONTENTS';
export const LOAD_DEFAULT_SUB_MENU = 'hco/App/LOAD_DEFAULT_SUB_MENU';
export const LOAD_SUB_MENU_CONTENTS_SUCCESS = 'hco/App/LOAD_SUB_MENU_CONTENTS_SUCCESS';
export const LOAD_SUB_MENU_CONTENTS_ERROR = 'hco/App/LOAD_SUB_MENU_CONTENTS_ERROR';

export const MENU_IS_OPEN = 'hco/App/MENU_IS_OPEN';

// Authentication
export const SET_AUTH_STATE = 'hco/App/SET_AUTH_STATE';

// Mobile
export const TOGGLE_MOBILE_STATE = 'hco/App/TOGGLE_MOBILE_STATE';

// Breadcrumb bar
export const GET_BREADCRUMB = 'hco/App/GET_BREADCRUMB';
export const SET_BREADCRUMB = 'hco/App/SET_BREADCRUMB';

// Menus
export const SET_TERTIARY_MENU = 'hco/App/SET_TERTIARY_MENU';

// Site overlay
export const SET_OVERLAY_VISIBILITY = 'hco/App/SET_OVERLAY_VISIBILITY';

// LicenseBar visibility
export const LICENSE_BAR_LOCATION = 'hco/App/LICENSE_BAR_LOCATION';

// Selected and stored license details
export const SET_LICENSE = 'hco/App/SET_LICENSE';

// Breakpoints/media queries used on the client side
export const SET_ACTIVE_BREAKPOINTS = 'hco/App/SET_ACTIVE_BREAKPOINTS';

// Errors
export const SET_ERROR = 'hco/App/SET_ERROR';
export const CLEAR_ERROR = 'hco/App/CLEAR_ERROR';

// Alert
export const SET_ALERT = 'hco/App/SET_ALERT';
export const CLEAR_ALERT = 'hco/App/CLEAR_ALERT';

// Cart
export const GET_CART = 'hco/App/GET_CART';
export const GET_CART_ERROR = 'hco/App/GET_CART_ERROR';
export const GET_CART_SUCCESS = 'hco/App/GET_CART_SUCCESS';
export const ADD_TO_CART = 'hco/App/ADD_TO_CART';
export const REMOVE_FROM_CART = 'hco/App/REMOVE_FROM_CART';
export const CART_IS_UPDATING = 'hco/App/CART_IS_UPDATING';
export const CART_UPDATE_SUCCESS = 'hco/App/CART_UPDATE_SUCCESS';
export const CART_UPDATE_ERROR = 'hco/App/CART_UPDATE_ERROR';
export const UPDATE_CART_ITEMS = 'hco/App/UPDATE_CART_ITEMS';
export const UPDATE_CART_ITEMS_SUCCESS = 'hco/App/UPDATE_CART_ITEMS_SUCCESS';
export const UPDATE_CART_ITEMS_ERROR = 'hco/App/UPDATE_CART_ITEMS_ERROR';
export const CLEAR_CART = 'hco/App/CLEAR_CART';

// Discounts
export const GET_DISCOUNTS = 'hco/App/GET_DISCOUNTS';

// Get all prices for a set of packages
export const GET_PRICES = 'hco/App/GET_PRICES';
export const GET_PRICES_SUCCESS = 'hco/App/GET_PRICES_SUCCESS';
export const GET_PRICES_ERROR = 'hco/App/GET_PRICES_ERROR';
export const CLEAR_PRICES = 'hco/App/CLEAR_PRICES';

// Get favorites
export const LOAD_FAVORITES = 'hco/App/LOAD_FAVORITES';
export const LOAD_FAVORITES_SUCCESS = 'hco/App/LOAD_FAVORITES_SUCCESS';
export const LOAD_FAVORITES_ERROR = 'hco/App/LOAD_FAVORITES_ERROR';
export const CLEAR_FAVORITES = 'hco/App/CLEAR_FAVORITES';

export const TOGGLE_FAVORITE = 'hco/App/TOGGLE_FAVORITE';
export const TOGGLE_FAVORITE_SUCCESS = 'hco/App/TOGGLE_FAVORITE_SUCCESS';
export const TOGGLE_FAVORITE_ERROR = 'hco/App/TOGGLE_FAVORITE_ERROR';
export const TOGGLE_FAVORITE_HOLDING = 'hco/App/TOGGLE_FAVORITE_HOLDING';
export const REMOVE_FAVORITE_HOLDING = 'hco/App/REMOVE_FAVORITE_HOLDING';

// SiteHeader state
export const SET_SITE_HEADER_STATE = 'hco/App/SET_SITE_HEADER_STATE';
export const CLEAR_SITE_HEADER_STATE = 'hcp/App/CLEAR_SITE_HEADER_STATE';

// Collections
export const SET_COLLECTION = 'hco/App/SET_COLLECTION';
export const LOAD_COLLECTIONS = 'hco/App/LOAD_COLLECTIONS';
export const LOAD_COLLECTIONS_SUCCESS = 'hco/App/LOAD_COLLECTIONS_SUCCESS';
export const LOAD_COLLECTIONS_ERROR = 'hco/App/LOAD_COLLECTIONS_ERROR';
export const LOAD_COLLECTION = 'hco/App/LOAD_COLLECTION';
export const LOAD_COLLECTION_SUCCESS = 'hco/App/LOAD_COLLECTION_SUCCESS';
export const LOAD_COLLECTION_ERROR = 'hco/App/LOAD_COLLECTION_ERROR';
export const CLEAR_COLLECTION = 'hco/App/CLEAR_COLLECTION';

// Additional Collections
export const LOAD_ADDITIONAL_MENU_ITEMS = 'hco/App/LOAD_ADDITIONAL_MENU_ITEMS';
export const LOAD_ADDITIONAL_MENU_ITEMS_SUCCESS = 'hco/App/LOAD_ADDITIONAL_MENU_ITEMS_SUCCESS';
export const LOAD_ADDITIONAL_MENU_ITEMS_ERROR = 'hco/App/LOAD_ADDITIONAL_MENU_ITEMS_ERROR';
export const CLEAR_ADDITIONAL_MENU_ITEMS = 'hco/App/CLEAR_ADDITIONAL_MENU_ITEMS';

// Preload Submenus: Right now only Collections & FindByName
export const PRELOAD_SUBMENUS = 'hco/App/PRELOAD_SUBMENUS';
export const PRELOAD_SUBMENUS_SUCCESS = 'hco/App/PRELOAD_SUBMENUS_SUCCESS';
export const PRELOAD_SUBMENUS_ERROR = 'hco/App/PRELOAD_SUBMENUS_ERROR';

export const LOAD_FIND_BY_NAME = 'hco/App/LOAD_FIND_BY_NAME';
export const LOAD_FIND_BY_NAME_SUCCESS = 'hco/App/LOAD_FIND_BY_NAME_SUCCESS';
export const LOAD_FIND_BY_NAME_ERROR = 'hco/App/PLOAD_FIND_BY_NAME_ERROR';

export const LOAD_TYPE_CAPSULES = 'hco/App/LOAD_TYPE_CAPSULES';
export const LOAD_TYPE_CAPSULES_SUCCESS = 'hco/App/LOAD_TYPE_CAPSULES_SUCCESS';
export const LOAD_TYPE_CAPSULES_ERROR = 'hco/App/PLOAD_TYPE_CAPSULES_ERROR';

// Search constants
export const LOAD_SEARCH = 'hco/Search/LOAD_SEARCH';
export const LOAD_SEARCH_SUCCESS = 'hco/Search/LOAD_SEARCH_SUCCESS';
export const LOAD_SEARCH_ERROR = 'hco/Search/LOAD_SEARCH_ERROR';
export const CLEAR_SEARCH = 'hco/Search/CLEAR_SEARCH';

// Authentication modal
export const OPEN_AUTHENTICATION_MODAL = 'hco/App/OPEN_AUTHENTICATION_MODAL';
export const CLOSE_AUTHENTICATION_MODAL = 'hco/App/CLOSE_AUTHENTICATION_MODAL';

// Newsletter Subscription
export const SUBSCRIBE_NEWSLETTER = 'hco/App/SUBSCRIBE_NEWSLETTER';
export const UNSUBSCRIBE_NEWSLETTER = 'hco/App/UNSUBSCRIBE_NEWSLETTER';

// Promo codes
export const SET_PROMO_CODE = 'hco/Checkout/SET_PROMO_CODE';
export const SET_PROMO_CODE_ERROR = 'hco/Checkout/SET_PROMO_CODE_ERROR';
export const SET_PROMO_CODE_SUCCESS = 'hco/Checkout/SET_PROMO_CODE_SUCCESS';
export const CLEAR_PROMO_CODE = 'hco/Checkout/CLEAR_PROMO_CODE';
export const DELETE_PROMO_CODE = 'hco/Checkout/DELETE_PROMO_CODE';

// policy
export const GET_POLICY_TEXT = 'hco/policy/GET_POLICY_TEXT';
export const SET_POLICY_TEXT_LOADING = 'hco/policy/SET_POLICY_TEXT_LOADING';
export const SET_POLICY_TEXT_SUCCESS = 'hco/policy/SET_POLICY_TEXT_SUCCESS';
export const SET_POLICY_TEXT_ERROR = 'hco/policy/SET_POLICY_TEXT_ERROR';
// Contact Text Display
export const LOAD_CONTACT_TEXT_DISPLAY =
	'hco/contact/LOAD_CONTACT_TEXT_DISPLAY';
export const LOAD_CONTACT_TEXT_DISPLAY_SUCCESS =
	'hco/contact/LOAD_CONTACT_TEXT_DISPLAY_SUCCESS';
export const LOAD_CONTACT_TEXT_DISPLAY_ERROR =
	'hco/contact/LOAD_CONTACT_TEXT_DISPLAY_ERROR';
