/*
 * Account constants
 */

export const CLEAR = 'hco/Account/CLEAR';
export const CLEAR_USER = 'hco/Account/CLEAR_USER';

export const GET_COUNTRIES = 'hco/Account/GET_COUNTRIES';
export const GET_USER = 'hco/Account/GET_USER';

export const DELETE_USER_CREDIT_CARD = 'hco/Account/DELETE_USER_CREDIT_CARD';

export const SAVE_EMAIL_PREFERENCE = 'hco/Account/SAVE_EMAIL_PREFERENCE';
export const SAVE_USER_BILLING = 'hco/Account/SAVE_USER_BILLING';
export const SAVE_USER_MAILING = 'hco/Account/SAVE_USER_MAILING';
export const SAVE_USER_PAYMENT = 'hco/Account/SAVE_USER_PAYMENT';
export const SAVE_USER_PROFILE = 'hco/Account/SAVE_USER_PROFILE';

export const SET_USER_CREDIT_CARD = 'hco/Account/SET_USER_CREDIT_CARD';
export const SET_COUNTRIES = 'hco/Account/SET_COUNTRIES';
export const SET_EMAIL_PREFERENCE = 'hco/Account/SET_EMAIL_PREFERENCE';
export const SET_ERROR = 'hco/Account/SET_ERROR';
export const SET_LOADING = 'hco/Account/SET_LOADING';
export const SET_USER_BILLING = 'hco/Account/SET_USER_BILLING';
export const SET_USER_MAILING = 'hco/Account/SET_USER_MAILING';
export const SET_USER_PROFILE = 'hco/Account/SET_USER_PROFILE';

/* Self-hosted Licenses */
export const GET_SELF_HOSTED_UPGRADE_OVERVIEW = 'hco/Account/GET_SELF_HOSTED_UPGRADE_OVERVIEW';
export const SET_SELF_HOSTED_UPGRADE_OVERVIEW = 'hco/Account/SET_SELF_HOSTED_UPGRADE_OVERVIEW';

export const GET_SELF_HOSTED_RENEWAL_OVERVIEW = 'hco/Account/GET_SELF_HOSTED_RENEWAL_OVERVIEW';
export const SET_SELF_HOSTED_RENEWAL_OVERVIEW = 'hco/Account/SET_SELF_HOSTED_RENEWAL_OVERVIEW';

export const GET_SELF_HOSTED_PAGEVIEWS = 'hco/Account/GET_SELF_HOSTED_PAGEVIEWS';
export const SET_SELF_HOSTED_PAGEVIEWS = 'hco/Account/SET_SELF_HOSTED_PAGEVIEWS';

export const RESET_PASSWORD = 'hco/Account/RESET_PASSWORD';

export const VERIFY_ACCOUNT_DELETION = 'hco/Account/VERIFY_ACCOUNT_DELETION';
export const DELETE_ACCOUNT = 'hco/Account/DELETE_ACCOUNT';
export const SET_ACCOUNT_DELETION_VERIFICATION = 'hco/Account/SET_ACCOUNT_DELETION_VERIFICATION';
