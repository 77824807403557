/**
* app.js
*
* This is the entry file for the application, only setup and boilerplate
* code.
*/
import 'babel-polyfill';
import { last } from 'lodash';
import 'whatwg-fetch';
import 'raf/polyfill';

// IntersectionObserver polyfill. Used primarily on the home page.
import 'intersection-observer';

import smoothscroll from 'smoothscroll-polyfill';
// Kick off the smoothscroll polyfill
smoothscroll.polyfill();

import 'element-closest-polyfill';

import scrollToElement from 'utils/scrollToElement';

// Load the manifest.json file and the .htaccess file
import 'file-loader?name=[name].[ext]!./favicon.ico';
import 'file-loader?name=[name].[ext]!./.htaccess';

// Error boundary component for catching app errors
import ErrorBoundary from 'components/ErrorBoundary';

// CSS
import 'styles/grid.css';
import 'styles/fonts.css';


// Import all the third party stuff
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { applyRouterMiddleware, Router, browserHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { useScroll } from 'react-router-scroll';

import { touchevents } from 'modernizr';
global.hasTouchEvents = touchevents;
document.body.classList.add(touchevents ? 'touchevents' : 'no-touchevents');
import initReactFastclick from 'react-fastclick';
// If touchevents is true, initialize fastclick. This helps resolve the issue where clicking a menu
// item highlights/hovers the item that will appear in place of the originally tapped item.
if (touchevents) {
	initReactFastclick();
}

// Create redux store with history
// this uses the singleton browserHistory provided by react-router
// Optionally, this could be changed to leverage a created history
// e.g. `const browserHistory = useRouterHistory(createBrowserHistory)();`
import configureStore from './store';
const initialState = {};
const store = configureStore(initialState, browserHistory);


// Sync history and store, as the react-router-redux reducer
// is under the non-default key ("routing"), selectLocationState
// must be provided for resolving how to retrieve the "route" in the state
import { selectLocationState } from 'pages/App/selectors';
const history = syncHistoryWithStore(browserHistory, store, {
	selectLocationState: selectLocationState()
});

// On initial pageload & hard reload, the state.route.locationBeforeTransitions
// in selectLocationState() starts off with a null value that is pushed to
// browser's history before setting an actual route object with a valid pathname.
// The valid object is pushed to browser's history yet again. This results in an
// unwanted double entry into browser history. Going back once (below) fixes this
// issue. This goBack() method will call only once on initial pageload or hard
// reload. We're currently unsure if the problem originates with
// syncHistoryWithStore (and is a library problem) or elsewhere. Not the most
// elegant solution and perhaps further digging will result in an improved fix.
// As a possible exploration I tried unsuccessfully to hijack state.router in
// selectLocationState().
if (!touchevents) { history.goBack(); }


// Set up the router, wrapping all Routes in the App component
import createRootComponent from './routes';
const rootRoute = createRootComponent(store);


/**
 * Using React Router’s onUpdate() method, hashLinkScroll looks for a hash value
 * in the URL. If it finds one it starts a timer that looks for the corresponding
 * HTML element in the DOM. If one is found within the course of an arbitrary
 * loop limit (anchorCheckLimit) it scrolls the user to that element using
 * scrollIntoView. If one is not found by the time the loop expires, the
 * interval is cancelled.
 *
 * @method  hashLinkScroll
 */

const HASH_INTERVAL_TIME = 400;
let anchorTimer;
let anchorCheck = 0;
let currentId = '';
const anchorCheckLimit = 10;
function hashLinkScroll() {
	clearInterval(anchorTimer);
	const { hash } = window.location;
	if (hash !== '') {
		anchorTimer = setInterval(() => {
			const id = hash.replace('#', '');
			const element = document.getElementById(id);
			if (element) {
				if (currentId !== element.id) {
					currentId = element.id;
					scrollToElement(element);
				}
				clearInterval(anchorTimer);
			} else { anchorCheck += 1; }

			if (anchorCheck >= anchorCheckLimit) { clearInterval(anchorTimer); }
		}, HASH_INTERVAL_TIME);
	}
}


/**
 * Set up scroll behavior for various pages.
 *
 * @method  scrollBehavior
 * @return  {Boolean}
 */
const scrollBehavior = () => useScroll((prevRouterProps, { routes }) => {
	const isStylesOrPackagePage = ['styles', 'package'].indexOf(last(routes).name) > -1;

	// Primarily used for the Styles page, so that when you click on a package and the URI updates
	// the page doesn’t scroll you back to the top.
	if (
		prevRouterProps
		&& !isStylesOrPackagePage
		&& prevRouterProps
		&& last(prevRouterProps.routes).name !== last(routes).name // If the previous route’s last segment name is different than the new one…
	) { return true; }

	return false;
});


ReactDOM.render(
	<ErrorBoundary>
		<Provider store={ store }>
			<Router
				history={ history }
				onUpdate={ () => {
					hashLinkScroll();
				} }
				render={
					// Scroll to top when going to a new page, imitating default browser
					// behaviour
					applyRouterMiddleware(scrollBehavior())
				}
				routes={ rootRoute }
			/>
		</Provider>
	</ErrorBoundary>,
	document.getElementById('root')
);
