/* eslint-disable no-magic-numbers */


module.exports.errorMessages = {
	woffTimeout: 'It looks like we’re having some trouble loading some assets. We’ll continue to try loading them in the background while you continue browsing.'
};


module.exports.numValues = {
	woffLoadTimeout: 30000
};


module.exports.layoutBreakpoints = {
	desktop: 'gtLargeMedium',
	mobile: 'ltLargeMedium'
};


module.exports.formatting = {
	decimalPoints: 2,
	decimalReplacer: 'x'
};


module.exports.nodeTypes = {
	element: 1,
	text: 3
};


/**
 * Editor default values
 * Default toolbar values. Applied on load unless a setting/value is present as
 * a query string in the URL.
 */
module.exports.defaults = {
	// Editor defaults
	alignment: 'left',
	content: 'Try H&Co fonts at try.typography.com.',
	fontFamily: 100406,
	fontStyle: 1777,
	leading: 1,
	pm: 0,
	size: 10,
	tracking: 0
};


/**
 * Prefixes
 * These prefix strings are used in the classes that are applied to
 * selections in the editor. Not only when creating the classes, but also
 * when parsing them to update the toolbar.
 */
module.exports.prefix = {
	alignment: 'alignment__',
	font: 'font__',
	leading: 'leading__',
	pm: 'pm__',
	size: 'size__',
	tracking: 'tracking__',
	features: 'features__'
};


/**
 * OpenType feature groupings
 * Treats the included features as mutually-exclusive toggles. Radio buttons.
 */
module.exports.featureGroups = {
	figures: ['lnum', 'onum', 'tnum', 'ordn']
};


/**
 * In order to make family names in CSS classes match up to what is pulled from
 * the data, we have to make a few adjustments. Namely removing characters that
 * aren’t allowed in CSS classes, like ampersands and periods.
 */
module.exports.exceptions = {
	nitroTurbo: /&\s{1}/
};


/**
 * CSS Properties
 * Mapping of prefixes to CSS property names.
 */
module.exports.cssProperties = {
	alignment: 'text-align',
	font: null,
	leading: 'line-height',
	pm: 'margin-bottom',
	size: 'font-size',
	tracking: 'letter-spacing',
	features: 'font-feature-settings'
};


module.exports.language = {
	multipleStylesSelected: 'multiple'
};


module.exports.defaultLabelFont = [
	[
		'@font-face',
		['font-family', 'IdlewildSSm-Medium-TDB'],
		['font-weight', 'normal'],
		['font-style', 'normal'],
		['src', 'url(/overview/fonts/woff/IdlewildSSm-Medium-TD-Space.woff)']
	], [
		'@font-face',
		['font-family', 'IdlewildSSm-Medium-TDA'],
		['font-weight', 'normal'],
		['font-style', 'normal'],
		['src', 'url(/overview/fonts/woff/IdlewildSSm-Medium-TD.woff)']
	], [
		'@font-face',
		['font-family', 'IdlewildSSm-Book-TDB'],
		['font-weight', 'normal'],
		['font-style', 'normal'],
		['src', 'url(/overview/fonts/woff/IdlewildSSm-Book-TD-Space.woff)']
	], [
		'@font-face',
		['font-family', 'IdlewildSSm-Book-TDA'],
		['font-weight', 'normal'],
		['font-style', 'normal'],
		['src', 'url(/overview/fonts/woff/IdlewildSSm-Book-TD.woff)']
	], [
		'@font-face',
		['font-family', 'IdlewildSSm-Light-TDB'],
		['font-weight', 'normal'],
		['font-style', 'normal'],
		['src', 'url(/overview/fonts/woff/IdlewildSSm-Light-TD-Space.woff)']
	], [
		'@font-face',
		['font-family', 'IdlewildSSm-Light-TDA'],
		['font-weight', 'normal'],
		['font-style', 'normal'],
		['src', 'url(/overview/fonts/woff/IdlewildSSm-Light-TD.woff)']
	]
];


/**
 * Styles that should be excluded from the style menu. These are generally
 * things like layered styles — features that we don’t yet have built in.
 */
module.exports.stylesMenuBlacklist = [
	1781, // Peristyle Peristyle Stencil Layer One
	1782 // Peristyle Peristyle Stencil Layer Two
];


/**
 * All possible options for the tools in the toolbar. No actual values are set
 * here. Just includes all available options for each tool. For openTypeFeatures
 * and stylisticSets, only the values present in the style that’s loaded will
 * be used from this config.
 */
module.exports.toolbarOptions = {
	size: {
		min: 0.7377049180327869,
		max: 30,
		step: 0.081967213114754
	},

	leading: {
		min: 0.5,
		max: 3,
		step: 0.001
	},

	pm: {
		min: -1,
		max: 12,
		step: 0.005
	},

	tracking: {
		min: -0.15,
		max: 0.5,
		step: 0.001
	},

	openTypeFeatures: [
		{
			value: 'liga',
			label: 'Ligatures'
		}, {
			value: 'dlig',
			label: 'Discretionary Ligatures'
		}, {
			value: 'calt',
			label: 'Contextual Alternates'
		}, {
			value: 'smcp',
			label: 'Small Caps'
		}, {
			value: 'swsh',
			label: 'Swashes'
		}, {
			value: 'salt',
			label: 'Stylistic Alternates'
		}, {
			value: 'frac',
			label: 'Fractions'
		}, {
			value: 'onum',
			label: 'Old-Style Figures'
		}, {
			value: 'lnum',
			label: 'Lining Figures'
		}, {
			value: 'tnum',
			label: 'Tabular Figures'
		}, {
			value: 'ordn',
			label: 'Ordinals'
		}
	],
	stylisticSets: [
		{
			value: 'ss01',
			label: 'SS01'
		}, {
			value: 'ss02',
			label: 'SS02'
		}, {
			value: 'ss03',
			label: 'SS03'
		}, {
			value: 'ss04',
			label: 'SS04'
		}, {
			value: 'ss05',
			label: 'SS05'
		}, {
			value: 'ss06',
			label: 'SS06'
		}, {
			value: 'ss07',
			label: 'SS07'
		}, {
			value: 'ss08',
			label: 'SS08'
		}, {
			value: 'ss09',
			label: 'SS09'
		}, {
			value: 'ss10',
			label: 'SS10'
		}, {
			value: 'ss11',
			label: 'SS11'
		}, {
			value: 'ss12',
			label: 'SS12'
		}, {
			value: 'ss13',
			label: 'SS13'
		}, {
			value: 'ss14',
			label: 'SS14'
		}, {
			value: 'ss15',
			label: 'SS15'
		}, {
			value: 'ss16',
			label: 'SS16'
		}, {
			value: 'ss17',
			label: 'SS17'
		}, {
			value: 'ss18',
			label: 'SS18'
		}, {
			value: 'ss19',
			label: 'SS19'
		}, {
			value: 'ss20',
			label: 'SS20'
		}
	]
};


module.exports.sliderStyles = {
	handleStyle: [
		{ backgroundColor: 'rgb(60, 158, 255)', borderColor: 'transparent', borderRadius: 5, boxShadow: '0 0 0 3px white', height: 24, marginTop: -11, width: 5 }
	],
	railStyle: { backgroundColor: 'rgb(214, 214, 214)', cursor: 'pointer', height: 2 },
	trackStyle: [
		{ backgroundColor: 'rgb(214, 214, 214)', height: 2 }
	]
};
