/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 */
import { fromJS } from 'immutable';
import { chain, compact, each, find, get, map, remove } from 'lodash';
import camelcase from 'camelize';

import {
	CLEAR_CHARACTERS,
	CLEAR_GALLERY,
	CLEAR_PACKAGES,
	CLEAR_PRODUCT_LINE,
	CLEAR_RESIZE_SETTINGS,
	CLEAR_TRY_LAYOUT,
	GET_PACKAGE_STYLES_SUCCESS,
	GET_PACKAGE_STYLES_ERROR,
	GET_SPECIAL_OFFERS,
	GET_SPECIAL_OFFERS_SUCCESS,
	GET_SPECIAL_OFFERS_ERROR,
	GET_STYLE_BY_ID,
	LOAD_CHARACTERS_ERROR,
	LOAD_CHARACTERS_SUCCESS,
	LOAD_CHARACTERS,
	LOAD_GALLERY,
	LOAD_GALLERY_SUCCESS,
	LOAD_GALLERY_ERROR,
	LOAD_HOW_TO_USE,
	LOAD_HOW_TO_USE_SUCCESS,
	LOAD_HOW_TO_USE_ERROR,
	CLEAR_HOW_TO_USE,
	LOAD_ONELINERS,
	LOAD_ONELINERS_SUCCESS,
	LOAD_ONELINERS_ERROR,
	LOAD_PACKAGES,
	LOAD_PACKAGES_ERROR,
	LOAD_PACKAGES_SUCCESS,
	LOAD_PRODUCTS_ERROR,
	LOAD_PRODUCTS_SUCCESS,
	LOAD_PRODUCTS,
	LOAD_PRODUCT_STYLES_ERROR,
	LOAD_PRODUCT_STYLES_SUCCESS,
	LOAD_PRODUCT_STYLES,
	LOAD_TRY_LAYOUT_ERROR,
	LOAD_TRY_LAYOUT_SUCCESS,
	LOAD_TRY_LAYOUT,
	SET_CHARACTERS,
	SET_CONTROLS_OPEN,
	SET_PRODUCT_LINE,
	SET_RESIZE_SETTINGS,
	LOAD_SUGGESTED_PAIRINGS,
	LOAD_SUGGESTED_PAIRINGS_SUCCESS,
	LOAD_SUGGESTED_PAIRINGS_ERROR,
	DOWNLOAD_FONT_PACKAGE,
	DOWNLOAD_FONT_PACKAGE_ERROR,
	DOWNLOAD_FONT_PACKAGE_SUCCESS
} from './constants';

// The initial state of the App
const initialState = fromJS({
	characters: null,
	charactersLoading: true,
	cloudStyles: null,
	cloudStylesLoading: true,
	customPackages: null,
	customPackagesError: false,
	customPackagesLoading: false,
	editorControlsOpen: false,
	fontPackageDownloading: false,
	fontPackageDownloadingError: false,
	gallery: null,
	galleryLoading: true,
	galleryLoadingError: false,
	howToUse: null,
	howToUseLoading: true,
	howToUseLoadingError: false,
	oneLiners: null,
	oneLinersLoading: true,
	oneLinersLoadingError: false,
	packages: null,
	packagesLoading: false,
	packageStyles: null,
	packageStylesError: false,
	packageStylesLoading: true,
	productFeaturesLoading: true,
	productFeatures: {},
	productLine: null,
	products: null,
	productsLoading: true,
	productStylesLoading: true,
	error: false,
	productStyles: null,
	relatedPackages: null,
	resizeSettings: null,
	specialOffers: null,
	specialOffersLoading: false,
	suggestedPairings: null,
	suggestedPairingsError: false,
	suggestedPairingsLoading: true,
	tryLayout: null,
	tryLayoutError: false
});

function appReducer(state = initialState, action) {
	let suggestedPairings;
	let products;

	switch (action.type) {
		// Set product line
		case SET_PRODUCT_LINE:
			return state
				.set('productLine', action.data);

		// Clear product line
		case CLEAR_PRODUCT_LINE:
			return state
				.set('productLine', null);

		// Load fonts
		case LOAD_PRODUCTS:
			return state
				.set('productsLoading', true)
				.set('error', false)
				.set('products', null);

		case LOAD_PRODUCTS_SUCCESS:
			return state
				.set('products', action.data.data)
				.set('productsLoading', false);

		case LOAD_PRODUCTS_ERROR:
			return state
				.set('error', action.error)
				.set('productsLoading', false);

		// Load product styles
		case LOAD_PRODUCT_STYLES:
			return state
				.set('productStylesLoading', true)
				.set('error', false)
				.set('productStyles', null);

		case LOAD_PRODUCT_STYLES_SUCCESS:
			return state
				.set('productStyles', action.data.included)
				.set('productStylesLoading', false);

		case LOAD_PRODUCT_STYLES_ERROR:
			return state
				.set('error', action.error)
				.set('productStylesLoading', false);

		// Load packages
		case LOAD_PACKAGES:
			return state
				.set('packages', null)
				.set('relatedPackages', null)
				.set('packagesLoading', false)
				.set('error', false);

		// Load font packages by product ID
		case LOAD_PACKAGES_SUCCESS: {
			const packages = camelcase(action.data.packages);
			const relatedPackages = camelcase(action.data.relatedPackages);

			const usedSiblings = [];
			const formattedData = map(packages, (item) => {
				// Collect all items from the data array that match IDs in this item’s siblingItems array.
				const siblings = chain(packages)
					.keyBy('id')
					.at(item.siblingItems)
					.value();

				// Add this item’s siblingItems IDs to the usedSiblings array for comparison below.
				if (item.siblingItems.length) {
					usedSiblings.push(...item.siblingItems);
				}

				return {
					...item,
					siblings
				};
			});

			// Remove all packages that are siblings of another package
			each(usedSiblings, (id) => {
				remove(formattedData, (sib) => sib.id === id);
			});

			return state
				.set('packagesLoading', false)
				.set('packages', compact(formattedData))
				.set('relatedPackages', relatedPackages);
		}

		case LOAD_PACKAGES_ERROR:
			return state
				.set('error', action.error)
				.set('packagesLoading', false);

		// Clear  packages
		case CLEAR_PACKAGES: {
			return state
				.set('packages', null)
				.set('packagesLoading', false)
				.set('relatedPackages', null);
		}

		// All of the above, but with character
		case SET_CHARACTERS:
			return state
				.set('characters', action.data);

		// Clear character
		case CLEAR_CHARACTERS:
			return state
				.set('characters', null);

		// Load font characters by their ID
		case LOAD_CHARACTERS:
			return state
				.set('charactersLoading', true)
				.set('error', false)
				.set('characters', null);

		case LOAD_CHARACTERS_SUCCESS:
			return state
				.set('characters', action.data)
				.set('charactersLoading', false);

		case LOAD_CHARACTERS_ERROR:
			return state
				.set('error', action.error)
				.set('charactersLoading', false);

		// Get special offers for current product line
		case GET_SPECIAL_OFFERS:
			return state
				.set('specialOffersLoading', true)
				.set('specialOffersError', false)
				.set('specialOffers', null);

		case GET_SPECIAL_OFFERS_SUCCESS:
			return state
				.set('specialOffers', action.data)
				.set('specialOffersLoading', false);

		case GET_SPECIAL_OFFERS_ERROR:
			return state
				.set('specialOffersError', action.error)
				.set('specialOffersLoading', false);

		// Resize settings for one-liner range slider
		case SET_RESIZE_SETTINGS:
			return state
				.set('resizeSettings', action.data);

		case CLEAR_RESIZE_SETTINGS:
			return state
				.set('resizeSettings', null);

		// Package styles
		case GET_PACKAGE_STYLES_SUCCESS:
			return state
				.set('packageStyles', action.data)
				.set('packageStylesError', false)
				.set('packageStylesLoading', false);

		case GET_PACKAGE_STYLES_ERROR:
			return state
				.set('packageStylesError', action.error)
				.set('packageStylesLoading', false);

		// Load gallery
		case LOAD_GALLERY:
			return state
				.set('galleryLoading', true)
				.set('galleryLoadingError', false)
				.set('gallery', null);

		case LOAD_GALLERY_SUCCESS: {
			return state
				.set('gallery', camelcase(action.data))
				.set('galleryLoadingError', false)
				.set('galleryLoading', false);
		}

		case LOAD_GALLERY_ERROR:
			return state
				.set('galleryLoadingError', action.error)
				.set('galleryLoading', false);

		case CLEAR_GALLERY:
			return state
				.set('gallery', null)
				.set('galleryLoadingError', false)
				.set('galleryLoading', false);

		// Load How to Use
		case LOAD_HOW_TO_USE:
			return state
				.set('howToUseLoading', true)
				.set('howToUseLoadingError', false)
				.set('howToUse', null);

		case LOAD_HOW_TO_USE_SUCCESS:
			return state
				.set('howToUse', camelcase(action.data))
				.set('howToUseLoadingError', false)
				.set('howToUseLoading', false);

		case LOAD_HOW_TO_USE_ERROR:
			return state
				.set('howToUseLoadingError', action.error)
				.set('howToUseLoading', false);

		case CLEAR_HOW_TO_USE:
			return state
				.set('howToUse', null);

		// Load one-liners
		case LOAD_ONELINERS:
			return state
				.set('oneLinersLoading', true)
				.set('oneLinersLoadingError', false)
				.set('oneLiners', null);

		case LOAD_ONELINERS_SUCCESS:
			return state
				.set('oneLiners', action.data)
				.set('oneLinersLoadingError', false)
				.set('oneLinersLoading', false);

		case LOAD_ONELINERS_ERROR:
			return state
				.set('oneLinersLoadingError', action.error)
				.set('oneLinersLoading', false);

		// Suggested Pairings
		case LOAD_SUGGESTED_PAIRINGS:
			return state
				.set('suggestedPairings', null)
				.set('suggestedPairingsLoading', true)
				.set('suggestedPairingsError', false);

		case LOAD_SUGGESTED_PAIRINGS_SUCCESS:
			// TODO: eliminate below
			// Paired product line slug should be pulled directly from the API
			products = state.get('products');
			suggestedPairings = camelcase(action.data);

			if (products) {
				suggestedPairings = map(suggestedPairings, (item) => {
					const suggestedPairing = item;
					const pairedProduct = find(state.get('products'), { id: item.withProductLineId.toString() });
					suggestedPairing.withProductLineSlug = pairedProduct.attributes.slug;
					return suggestedPairing;
				});
			}
			return state
				.set('suggestedPairings', suggestedPairings)
				.set('suggestedPairingsLoading', false)
				.set('suggestedPairingsError', false);

		case LOAD_SUGGESTED_PAIRINGS_ERROR:
			return state
				.set('suggestedPairingsLoading', false)
				.set('suggestedPairingsError', action.error);

		// Downlaod Font packages
		case DOWNLOAD_FONT_PACKAGE:
			return state
				.set('fontPackageDownloading', true)
				.set('fontPackageDownloadingError', false);

		case DOWNLOAD_FONT_PACKAGE_SUCCESS:
			return state
				.set('fontPackageDownloading', false)
				.set('fontPackageDownloadingError', false);

		case DOWNLOAD_FONT_PACKAGE_ERROR:
			return state
				.set('fontPackageDownloading', false)
				.set('fontPackageDownloadingError', action.error);

		// Load Try layouts
		case LOAD_TRY_LAYOUT:
			return state
				.set('tryLayout', null)
				.set('tryLayoutError', false);

		case LOAD_TRY_LAYOUT_SUCCESS: {
			return state
				.updateIn(['tryLayout'],
					(item) => ({
						...item,
						desktop: get(action, 'data.desktop'),
						mobile: get(action, 'data.mobile')
					})
				);
		}

		case LOAD_TRY_LAYOUT_ERROR:
			return state
				.set('tryLayoutError', action.error)
				.set('tryLayout', null);

		// Clear Try layout
		case CLEAR_TRY_LAYOUT:
			return state
				.set('tryLayout', null);


		// Overview editor controls
		case SET_CONTROLS_OPEN:
			return state
				.set('editorControlsOpen', action.state);

		case GET_STYLE_BY_ID:
			return state;

		default:
			return state;
	}
}

export default appReducer;
