const breakpoints = {
	xSmall: {
		device: 'iPhone 5/SE',
		height: 568,
		width: 320
	},
	small: {
		device: 'iPhone 6/7',
		height: 667,
		width: 375
	},
	smallMedium: {
		device: 'iPhone 6/7 Plus',
		height: 736,
		width: 414
	},
	medium: {
		device: '',
		height: 1024,
		width: 595
	},
	largeMedium: {
		device: 'iPad Retina/Air/Pro 9.7"',
		height: 1024,
		width: 768
	},
	large: {
		device: 'iPad Pro 12.9"',
		height: 1366,
		width: 1024
	},
	xLarge: {
		device: 'MacBook Air 11"',
		height: 768,
		width: 1220
	},
	xxLarge: {
		device: 'Desktop',
		height: 1200,
		width: 1600
	},
	giant: {
		device: 'Desktop 2',
		height: 1200,
		width: 1850
	}
};
module.exports.breakpoints = breakpoints;

module.exports = {
	// Vars for export
	'--xSmall': breakpoints.xSmall.width,
	'--small': breakpoints.small.width,
	'--smallMedium': breakpoints.smallMedium.width,
	'--medium': breakpoints.medium.width,
	'--largeMedium': breakpoints.largeMedium.width,
	'--large': breakpoints.large.width,
	'--xLarge': breakpoints.xLarge.width,
	'--xxLarge': breakpoints.xxLarge.width,
	'--giant': breakpoints.giant.width,

	/* Precise breakpoints */
	'--bp-xsmall': `(max-width: ${breakpoints.xSmall.width - 1}px)`,
	'--bp-small': `(min-width: ${breakpoints.xSmall.width}px) and (max-width: ${breakpoints.small.width - 1}px)`,
	'--bp-smallMedium': `(min-width: ${breakpoints.small.width}px) and (max-width: ${breakpoints.smallMedium.width - 1}px)`,
	'--bp-medium': `(min-width: ${breakpoints.smallMedium.width}px) and (max-width: ${breakpoints.medium.width - 1}px)`,
	'--bp-largeMedium': `(min-width: ${breakpoints.medium.width}px) and (max-width: ${breakpoints.largeMedium.width - 1}px)`,
	'--bp-large': `(min-width: ${breakpoints.largeMedium.width}px) and (max-width: ${breakpoints.large.width - 1}px)`,
	'--bp-xLarge': `(min-width: ${breakpoints.large.width}px) and (max-width: ${breakpoints.xLarge.width - 1}px)`,
	'--bp-xxLarge': `(min-width: ${breakpoints.xLarge.width}px) and (max-width: ${breakpoints.xxLarge.width - 1}px)`,
	'--bp-giant': `(min-width: ${breakpoints.xxLarge.width}px)`,

	/* "Greater-than" breakpoints */
	'--gt-bp-xSmall': `(min-width: ${breakpoints.xSmall.width}px)`,
	'--gt-bp-small': `(min-width: ${breakpoints.small.width}px)`,
	'--gt-bp-smallMedium': `(min-width: ${breakpoints.smallMedium.width}px)`,
	'--gt-bp-medium': `(min-width: ${breakpoints.medium.width}px)`,
	'--gt-bp-largeMedium': `(min-width: ${breakpoints.largeMedium.width}px)`,
	'--gt-bp-large': `(min-width: ${breakpoints.large.width}px)`,
	'--gt-bp-xLarge': `(min-width: ${breakpoints.xLarge.width}px)`,
	'--gt-bp-xxLarge': `(min-width: ${breakpoints.xxLarge.width}px)`,
	'--gt-bp-giant': `(min-width: ${breakpoints.giant.width}px)`,

	/* "Less-than" breakpoints */
	'--lt-bp-xSmall': `(max-width: ${breakpoints.xSmall.width - 1}px)`,
	'--lt-bp-small': `(max-width: ${breakpoints.small.width - 1}px)`,
	'--lt-bp-smallMedium': `(max-width: ${breakpoints.smallMedium.width - 1}px)`,
	'--lt-bp-medium': `(max-width: ${breakpoints.medium.width - 1}px)`,
	'--lt-bp-largeMedium': `(max-width: ${breakpoints.largeMedium.width - 1}px)`,
	'--lt-bp-large': `(max-width: ${breakpoints.large.width - 1}px)`,
	'--lt-bp-xLarge': `(max-width: ${breakpoints.xLarge.width - 1}px)`,
	'--lt-bp-xxLarge': `(max-width: ${breakpoints.xxLarge.width - 1}px)`,
	'--lt-bp-giant': `(max-width: ${breakpoints.giant.width - 1}px)`
};
