/* eslint-disable no-magic-numbers */

import { sources } from '../internals/config';

/**
 * HCo config
 *
 * This file should only contain configuration variables that will be used at application runtime.
 * Anything that relates to project setup or compilation can be found in /internals/config.
 *
 * All entries here should be in alphabetical order, including the default export at the bottom.
 * Please also add a comment to each to explain why it’s necessary and how it’s used.
 */
module.exports = {
	accountMenu: [
		{
			name: null,
			options: [
				{
					label: 'Account Details',
					icon: 'credit-card',
					link: '/account/details'
				}
			]
		}, {
			name: 'Purchases',
			options: [
				{
					label: 'Font Library',
					icon: 'books',
					link: '/account/font-library'
				}, {
					label: 'Order History',
					icon: 'generic',
					link: '/account/order-history'
				}
			]
		}, {
			name: 'Subscriptions',
			options: [
				{
					label: 'Manage Subscriptions',
					icon: 'generic',
					link: '/account/subscriptions'
				}, {
					label: 'Cloud Dashboard',
					icon: 'generic',
					link: '/dashboard'
				}, {
					label: 'Usage Reports',
					icon: 'generic',
					link: '/usage-reports'
				}
			]
		}, {
			name: 'Design Resources',
			options: [
				{
					label: 'Favorites',
					icon: 'wishlist',
					link: '/favorites'
				}
			]
		}, {
			name: ' ',
			options: [
				{
					label: 'Logout',
					icon: 'generic',
					link: '/account/signout'
				}
			]
		}
	],

	cdn: 'https://d31td5fkd89rr1.cloudfront.net/',

	// In descending order. Important that they remain in this order as they’re used to match
	// against product_line-specific options pulled in from the API.
	breakpoints: [
		'gtXLarge',
		'gtLarge',
		'gtLargeMedium',
		'gtMedium',
		'gtSmallMedium',
		'gtSmall',
		'gtXSmall'
	],


	// Cart config it,ms
	cart: {
		types: {
			app_license: {
				name: 'app',
				icon: { name: 'calendar-light' },
				iconRemoved: { name: 'calendar' }
			},
			cloud_license: {
				name: 'cloud',
				icon: { name: 'calendar-light' },
				iconRemoved: { name: 'calendar' }
			},
			computer_license: {
				name: 'computer',
				icon: null
			},
			web_font_license: {
				name: 'webfont',
				icon: null
			},
			self_hosted_license: {
				name: 'self-hosted',
				icon: { name: 'calendar' }
			}
		},
		promoCodeTypes: {
			0: 'FIXED',
			1: 'PERCENTAGE'
		}
	},

	charactersMaxColumns: {
		's-a': 12,
		's-b': 9,
		's-c': 9,
		's-d': 5,
		's-e': 9,
		's-f': 12
	},


	charactersMaxZoomLevel: {
		's-a': 9,
		's-b': 6,
		's-c': 6,
		's-d': 4,
		's-e': 6,
		's-f': 9
	},


	charactersViewboxData: {
		's-a': '0 0 62 63',
		's-b': '0 0 83 63',
		's-c': '0 0 83 124',
		's-d': '0 0 125 124',
		's-e': '0 0 83 83',
		's-f': '0 0 62 124'
	},


	colophonBgColor: 'rgba(106, 106, 119, 1)',


	// Cookies and session d,ta
	cookies: {
		accessToken: 'access_token',
		sessionToken: 'php_session',
		reauthenticationToken: 'reauthentication_token',
		reauthenticationTimeout: 900000, // ms
		userEmail: 'user_email'
	},


	date: {
		monthsInYear: 12
	},


	ee: {
		fieldPrefixes: {
			news: 'feature',
			edit: 'edit'
		}
	},


	errorCodes: {
		ACCOUNT_NOT_FOUND: 46,
		ACCOUNT_MISSING_PASSWORD: 48,
		INVALID_ARGUMENT: 34,
		INVALID_CREDENTIALS: 24,
		INVALID_TOKEN: 33,
		MAIL_CHIMP_REQUEST: 111,
		MISSING_TOKEN: 37,
		REAUTHENTICATION: 38,
		REDIRECTION: 300,
		SUCCESS: 200,
		UNAUTHORIZED: 35
	},


	features: {
		image: {
			defaultHeight: 300,
			defaultWidth: 500
		}
	},


	// Gallery settings
	gallery: {
		altSlug: '_alt',
		animation: {
			mainTransformDuration: 200
		},
		// Colors for the background layer
		background: {
			emptyTileColor: 'rgb(245, 245, 245)'
		},
		dogEar: {
			animationDelay: 300,
			animationDuration: 200,
			size: 32,
			staggerTiming: 60 // In milliseconds. Time to stagger intro of each dogEar by.
		},
		doubleTapZoomScale: 1.5,
		hotspots: {
			pulse: {
				delay: 800,
				duration: 750
			},
			radius: 21,
			strokeWidth: 5.6,
			colorDark: '#181818',
			colorLight: '#fff',
			isolateAnimationDuration: 300
		},
		initialZoom: {
			mobile: 2.1,
			desktop: 1
		},
		pageScrollDuration: 600,
		resizeDebounceDuration: 200,
		width: 1800
	},


	// Image settings
	images: {
		blank: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=',
		// Height of each individual line in a styles waterfall SVG
		styleLineHeight: 38
	},


	js: {
		debounceTiming: 300
	},


	keyCodes: {
		ENTER: 13,
		ESCAPE: 27,
		v: 86 // For pasting in the Editor.
	},


	licenseBar: {
		highlightFadeDelay: 3600
	},


	licenseRestrictions: {
		maxComputers: 200,
		minAppTitles: 1,
		maxAppTitles: 50,
		minAppTerm: 1,
		maxAppTerm: 3,
		defaultAppTerm: 1,
		highPageViewThreshold: 3000000,
		maxSelfHostedPageviews: 0
	},


	// License ty,es
	licenseTypes: [
		{
			name: 'Computers',
			dbName: 'computer_license',
			shortname: 'computer'
		}, {
			name: 'Cloud.typography',
			dbName: 'cloud_license',
			plans: ['200170'],
			shortname: 'cloud',
			term: 'year',
			unitPrice: 199
		}, {
			name: 'App.typography',
			dbName: 'app_license',
			plans: ['200219', '200221', '200222'],
			shortname: 'app',
			term: 'year',
			unitPrice: 299
		}, {
			name: 'Self-hosted',
			dbName: 'self_hosted_pageviews',
			shortname: 'selfHosted'
		}
	],


	navigationWhitelist: ['fonts', 'news', 'help', 'about'],


	numbers: ['zero', 'one', 'two', 'three', 'four', 'five'],


	oneLiners: {
		truncateAt: 13,
		minZoom: 2869,
		maxZoom: 36000
	},


	options: {
		cpus: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 125, 150, 175, 200, 201],
		cloud: {
			pageViews: [
				{
					value: 1003,
					label: '250,000 pv/mo'
				}, {
					value: 1004,
					label: '1,000,000 pv/mo'
				}
			]
		},
		app: {
			terms: [
				{ value: 1, label: '1 year' },
				{ value: 2, label: '2 years' },
				{ value: 3, label: '3 years' }
			]
		}
	},


	packageTypes: ['multi', 'screensmart', 'office'],


	routes: {
		home: {
			label: 'H&Co',
			pathname: 'home',
			pageHeader: false
		},
		fonts: {
			label: 'Fonts',
			name: 'fonts',
			breadcrumbBar: true,
			pageHeader: false,
			childRoutes: [
				{
					label: 'Overview',
					name: 'overview',
					path: '/fonts/:font/overview',
					includeInPageHeader: true,
					pageHeader: {
						id: 'fonts',
						collapsed: false,
						visible: true
					}
				// }, {
				// 	label: 'Gallery',
				// 	name: 'gallery',
				// 	path: '/fonts/:font/gallery',
				// 	includeInPageHeader: true,
				// 	pageHeader: {
				// 		id: 'fonts',
				// 		collapsed: false,
				// 		visible: true
				// 	}
				}, {
					label: 'Browse by Style',
					name: 'browsebystyle',
					includeInPageHeader: false,
					pageHeader: { visible: false }
				}, {
					label: 'Find by Name',
					name: 'find-by-name',
					includeInPageHeader: false,
					pageHeader: { visible: false }
				}, {
					label: 'Type Capsules',
					name: 'type-capsules',
					includeInPageHeader: false,
					pageHeader: { visible: false }
				}, {
					label: 'Packages',
					name: 'packages',
					path: '/fonts/:font/styles/:package',
					includeInPageHeader: false,
					pageHeader: {
						id: 'fonts',
						visible: true
					}
				}, {
					label: 'Styles',
					name: 'styles',
					path: '/fonts/:font/styles',
					includeInPageHeader: true,
					pageHeader: {
						id: 'fonts',
						visible: true
					}
				}, {
					label: 'Design Notes',
					name: 'design-notes',
					path: '/fonts/:font/design-notes',
					includeInPageHeader: true,
					pageHeader: {
						id: 'fonts',
						visible: true
					}
				}, {
					label: 'How to Use',
					name: 'how-to-use',
					path: '/fonts/:font/how-to-use',
					includeInPageHeader: true,
					pageHeader: {
						id: 'fonts',
						visible: true
					}
				}, {
					label: 'Characters',
					name: 'characters',
					path: '/fonts/:font/characters',
					includeInPageHeader: true,
					pageHeader: {
						id: 'fonts',
						visible: true
					}
				}, {
					label: 'Try',
					name: 'try',
					path: '/fonts/:font/try',
					includeInPageHeader: true,
					pageHeader: {
						id: 'fonts',
						visible: true
					}
				}
			]
		},
		news: {
			label: 'News',
			pathname: 'blog',
			breadcrumbBar: true,
			pageHeader: false
		},
		discover: {
			label: 'Discover',
			pathname: 'discover',
			breadcrumbBar: true,
			pageHeader: false
		},
		try: {
			label: 'Try',
			pathname: 'try',
			breadcrumbBar: true,
			pageHeader: false
		},
		help: {
			label: 'Help',
			pathname: 'help',
			breadcrumbBar: true,
			pageHeader: false
		},
		about: {
			label: 'About',
			pathname: 'about-us',
			breadcrumbBar: true,
			pageHeader: false
		},
		account: {
			label: 'Account',
			pathname: 'account',
			pageHeader: false
		},
		contact: {
			label: 'Contact',
			pathname: 'contact',
			pageTitle: 'Contact H&Co'
		},
		careers: {
			label: 'Careers',
			pathname: 'careers',
			breadcrumbBar: false,
			pageTitle: 'Careers at H&Co'
		},
		license: {
			label: 'License',
			pathname: 'license',
			pageTitle: 'H&Co EULA'
		}
	},


	selectMenu: {
		bottomSpacing: 24
	},


	sources: {
		apiUrl: `https://${process.env.API_HOST}.typography.com:${process.env.API_PORT}`,
		dashboardUrl: `https://${process.env.DASHBOARD_HOST}.typography.com`,
		cloudfrontURL: 'https://d31td5fkd89rr1.cloudfront.net'
	},


	steps: {
		checkout: {
			PAYMENT: 0,
			CONFIRMATION: 1,
			THANKS: 2
		}
	},


	styles: {
		imageURL: 'https://d31td5fkd89rr1.cloudfront.net/productLineImages/svg/',
		packages: [
			{
				label: '**{{name}}** <small>Multipurpose fonts</small>',
				name: 'multi'
			}, {
				label: '**{{name}} ScreenSmart** <small>for the web</small>',
				name: 'screensmart'
			}, {
				label: '**{{name}} Office** <small>for business</small>',
				name: 'office'
			}
		]
	},


	modulesHTU: {
		imageMargins: {
			top: -42,
			left: -49
		},
		imageScaling: {
			xSmall: 0.823,
			medium: 0.946,
			largeMedium: 1
		}
	},


	search: {
		maxLength: 64,
		popularSearches: [
			{ id: 'popularSearch_1', title: 'Gotham', link: { pathname: '/search', query: { term: 'gotham' } } },
			{ id: 'popularSearch_2', title: 'Web fonts', link: { pathname: '/search', query: { term: 'webfonts' } } },
			{ id: 'popularSearch_3', title: 'Cloud.typography', link: { pathname: '/search', query: { term: 'cloud.typography' } } },
			{ id: 'popularSearch_4', title: 'Serifs', link: { pathname: '/search', query: { term: 'serifs' } } },
			{ id: 'popularSearch_5', title: 'Archer', link: { pathname: '/search', query: { term: 'archer' } } }
		]
	},


	appSubscription: {
		item: {
			item_id: '200219',
			item_type: 'app',
			app_title_count: 1,
			license_term: 1
		}
	},


	cloudSubscription: {
		item: {
			item_id: 200170,
			item_type: 'cloud',
			value: 1,
			brand: 'Hoefler&Co',
			category: 'cloud_license',
			list: 'Web Fonts',
			name: 'Cloud.typography subscription',
			position: 1
		}
	}
};


module.exports.moduleToggle = {
	localImagePath: './images/module-toggle',
	remoteImagePath: `${sources.cloudfrontURL}/assets/images/modules`
};


module.exports.moduleStyleSwitcher = {
	localImagePath: '/images/module-style-switcher',
	remoteImagePath: `${sources.cloudfrontURL}/assets/images/how_to_use`
};

/**
 * “How to Use” Module Types
 *
 * Should match module’s channel shortname in ExpressionEngine
 * https://staging-ee.typography.com/admin.php?/cp/channels
 *
 * @type  {Object}
 */
module.exports.moduleTypes = {
	article: 'module_article',
	image: 'module_image_w_caption',
	languages: 'module_languages',
	nonInteractive: 'module_non_interactive',
	styleSwitcher: 'module_style_switcher',
	table: 'module_table',
	toggle: 'module_toggle'
};
