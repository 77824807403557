/**
 * Create the store with asynchronously loaded reducers
 */

import { createStore, applyMiddleware, compose } from 'redux';

import persistState from 'redux-localstorage';
import { serialize } from 'redux-localstorage-immutable';
import adapter from 'redux-localstorage/lib/adapters/localStorage';
import filter from 'redux-localstorage-filter';

import * as Sentry from '@sentry/browser';
Sentry.init({
	dsn: "https://d99309def4fc4ecfb0424dfedb0dc29b@o81166.ingest.sentry.io/176807",
	environment: process.env.NODE_ENV,
	integrations: [new Sentry.Integrations.GlobalHandlers({
		onunhandledrejection: true
	})],
	// The following line is replaced by the build script
	// The syntax required for the build script to replace a line is:
	// //<VARIABLE_NAME> <any text> ##<VARIABLE_NAME>##
	// The script will remove any preceeding //<VARIABLE_NAME> comment
	// and then replace the variable and it's octothorpes with the appropriate
	// variable at build time.
	//
	// Note: this is controlled in Dependencies/lib/bin/deploy/bin/deploy.py
	//
	// BEGIN SENTRY VARIABLE LIST
	//SENTRY_RELEASE release: "##SENTRY_RELEASE##"
	// END SENTRY VARIABLE LIST
});

import { routerMiddleware } from 'react-router-redux';
import createSagaMiddleware from 'redux-saga';
import createReducer from './reducers';

const sagaMiddleware = createSagaMiddleware();

export default function configureStore(initialState = {}, history) { // eslint-disable-line
	// Create the store with two middlewares
	// 1. sagaMiddleware: Makes redux-sagas work
	// 2. routerMiddleware: Syncs the location/URL path to the state
	const middlewares = [
		sagaMiddleware,
		routerMiddleware(history)
	];

	// If Redux DevTools Extension is installed use it, otherwise use Redux compose
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // eslint-disable-line no-underscore-dangle

	let storage;
	if (process.env.NODE_ENV !== 'test') {
		storage = compose(
			serialize,
			filter('licenses.licenses')
		)(adapter(window.localStorage));
	}

	const enhancers = [applyMiddleware(...middlewares)];
	if (process.env.NODE_ENV !== 'test') {
		enhancers.push(persistState(storage, 'HCo:licenses'));
	}

	const store = createStore(
		createReducer(),
		undefined,
		composeEnhancers(...enhancers)
	);

	// Create hook for async sagas
	store.runSaga = sagaMiddleware.run;

	// Make reducers hot reloadable, see http://mxs.is/googmo
	if (module.hot) {
		import('./reducers').then((reducerModule) => {
			const createReducers = reducerModule.default;
			const nextReducers = createReducers(store.asyncReducers);

			store.replaceReducer(nextReducers);
		});
	}

	// Initialize it with no other reducers
	store.asyncReducers = {};
	return store;
}
