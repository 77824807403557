/**
 * Language file
 * Storage for common and reusable content. Markdown capable.
 *
 * Currently using pope (https://github.com/poppinss/pope) for simple micro-
 * templating needs. To use:
 *
 * 		The micro-template format: 	welcome: Welcome, {{name}}.
 *
 * 		Using in the component:		import Lang from 'language';
 * 									import { pope } from 'pope';
 * 									pope(welcome, { name: 'Ian' })
 */
export const common = {
	companyName: 'Hoefler&Co.',
	companyNameShort: 'H&Co',
	buyFontsCTA: 'Get these fonts'
};

export const app = {
	addToCart: 'Add to Cart',
	pageTitle: `Fonts by ${common.companyName}`,
	errorMessage: 'Something went wrong, but it’s not you, it’s us.'
};

export const socialMeta = {
	summary: 'H&Co designs fonts for print, web, and mobile environments.',
	image: 'social/global.png',
	imageHeight: 628,
	imageWidth: 1200
};

/**
 * Page titles presented with Helmet
 */
export const policies = {
	'app-terms': 'App.typography Terms of Service',
	'cloud-terms': 'Cloud.typography Terms of Service',
	'web-fonts-terms': 'Web Fonts License Agreement',
	eula: 'End-User License Agreement'
};

export const siteHeader = {
	greeting: {
		known: 'Your Account',
		unknown: 'Sign In'
	}
};

export const siteFooter = {
	latest: {
		default: 'New at H&Co:'
	},
	newsletter: {
		cta: 'Be the first to hear \nabout new fonts!',
		ctaMobile: 'Be first to hear \nabout new fonts!',
		button: 'Subscribe',
		submitted: 'Thanks! We’ll keep you posted.'
	},
	offers: {
		default: 'Special Offers',
		cta: common.buyFontsCTA
	}
};

/**
 * Form language
 */
export const form = {
	changeCardText: 'Change payment method',
	creditCardStorageLabel: 'Use this as my stored credit card.',
	mailingAddressCheckboxLabel: 'Use this as my mailing address',
	passwordVisibilityHide: 'Hide',
	passwordVisibilityShow: 'Show',
	validationLabelOptional: '(if applicable)',
	validationLabelRequired: '',
	validationMessagePasswordComplexity: 'Does not meet requirements',
	validationMessagePasswordLength: 'Does not meet requirements',
	validationMessageRequired: 'Required'
};

/**
 * Checkout language
 */
export const checkout = {
	backButton: 'Go back',
	confirmationPageHeader: 'Complete your order.',
	continueButton: 'Save and continue',
	ctaHeader: 'Place your order',
	ctaNote:
		'Your card won’t be charged until you review and complete your order on the next screen.',
	editOrder: 'Edit order',
	error: {
		title: 'Transaction Error',
		message: '<p>We couldn’t process your transaction. Please check your credit card details and try again, and if you’re still having trouble, <a href="/contact">contact us</a>.</p><br/><p>Here’s the message we received from our payment processor, which might help identify what went wrong: “{{message}}”</p>'
	},
	licenseeHeader: 'Licensed to:',
	orderReviewHeader: 'Please review your order:',
	pageHeader: 'Payment details:',
	paymentPageHeader: 'Your Information',
	purchaserHeader: 'Purchased by:',
	submitButton: 'Place order',
	taxLabel: 'Sales Tax',
	updateCard: 'Please update your credit card information:'
};

/**
 * Global License Bar
 */
export const licenseBar = {
	label: 'Show prices for:',
	tooManyCPUs: 'More than 200 computers',
	noCPUs: 'No Computers',
	defaultComputerSelect: 'No Computers',
	freeFontsMessage: '_Your first {{count}} {{font}} are free!_',
	app: {
		newCustomerCTA: `With an App.typography subscription, you can
			publish apps, digital publications, and eBooks using
			_all_ of the H&Co fonts that you purchase for your
			computer — plus all the fonts you’ve purchased in the past.
			[Learn&nbsp;more.](/)`
	},
	cloud: {
		newCustomerCTA: `Use these fonts on the web with Cloud.typography.
			Subscriptions start at $199/year for sites with up to 250,000
			pageviews a month, and cover _all_ the H&Co fonts you’ve
			purchased for your computer. Plus, every subscription
			includes your first five web font packages _free_!
			[Learn&nbsp;more.](/)`,

		// Appears when customer has a cloud license and remaining free fonts
		freeFontCounter: 'With your Cloud.typography subscription,<br />your first **five** web fonts are free.'
	},
	licenseBar: {
		cloudDetailHeader: 'Choose the web font option <br />that’s right for you.',
		cloudDetailBody: 'Designed for website developers, Cloud.typography lets you serve the <em>entire</em> H&Co font library to all the websites you design, and deliver them using our world-class <small>cdn</small>. For more information, please contact our sales office.',
		cloudDetailBodyExistingUser: 'You’re currently subscribed to Cloud.typography, so you can access {{font}} for use on websites, as well as the rest of the entire H&Co library, on your <a href="/dashboard">Cloud Dashboard</a>.',
		selfHostingDetailBody: 'For website owners, H&Co web fonts can be downloaded in both <small>woff</small> and <small>woff2</small> format and hosted from your own server. Licenses are priced based on total monthly traffic.',
		appDetailBody: 'App.typography licenses, including enterprise and more flexible licensing options, are available through our sales office by emailing licensing@typography.com.'
	}
};

/**
 * Account
 */
export const account = {
	welcome: 'Welcome, {{name}}.',
	ui: {
		forgotPasswordHeader: 'Reset your password',
		forgotPasswordMessage: 'We’ll email you a link to reset your password.'
	}
};

/**
 * Cart
 */
export const cart = {
	ui: {
		cartCTA: 'Begin Checkout',
		deleteOnMobileLabel: 'Remove items',
		emptyCartHeader: 'Your cart is empty.',
		emptyCartMessage: 'Here are some of our current favorites if you’re looking for fonts to add.',
		finishedEditing: 'Done removing items',
		promoTypePercentage: '_{{percentage}}% off with code **{{code}}**_'
	},
	licenses: {
		app: {
			description: '{{term}}-year subscription for fonts in apps',
			titlesExceeded:
				'Please <a href="/contact">contact our sales office</a> to purchase fonts for more than 50 titles.'
		},
		cloud: {
			description: '{{term}}-year web font subscription'
		},
		computer: {
			description: '_for use with Cloud.typography_',
			titlesExceeded:
				'Please <a href="/contact">contact our sales office</a> to purchase fonts for more than 200 computers.'
		},
		webfont: {
			description: '_for use on websites via Cloud.typography_'
		},
		appfont: {
			description: '_for use with App.typography_'
		},
		'self-hosted': {
			description: '_Self-Hosting_',
			titlesExceeded:
				'Enterprise self-hosting plans, including unlimited traffic and more flexible licensing options, are available through our sales office.'
		}
	},
	confirmations: {
		changeComputerQuantities: {
			title:
				'Change number of computers for just this font, or all the ones in your cart?',
			message: '',
			buttons: {
				secondary: 'Yes — change all of them to {{count}} {{type}}',
				cancel: 'No — just change this one'
			}
		},
		removeCloudSubscription: {
			title: 'Are you sure?',
			message:
				'Tktk removing your Cloud.typography subscription will also remove all the web fonts in your cart.',
			buttons: {
				danger: 'Remove the subscription and web fonts',
				cancel: 'Go back without making changes'
			}
		}
	},
	reconciliation: {
		header: 'Welcome back! We’ve adjusted your cart:',
		cloud:
			'You already have a Cloud.typography subscription, so we’ve removed this one from your cart. _Saved you {{savings}}!_',
		app:
			'You already have an App.typography subscription, so we’ve removed this one from your cart. _Saved you {{savings}}!_',
		webfont:
			'We’ve removed the web fonts that you’ve already licensed. _Saved you {{savings}}!_'
	}
};

/**
 * 	Packages page
 */
export const packages = {
	buttonPrices: 'Show prices',
	buttonStyles: 'Show styles',
	inCartText: 'In Cart'
};

/**
 * 	Styles page
 */
export const styles = {
	sidebarHeader: 'Packages Available:',
	relatedPackagesHeader: 'Related Packages:'
};

export const pageHeader = {
	cta: common.buyFontsCTA
};

/**
 * Licensee Modal
 */
export const licenseeModal = {
	buttonSubmit: 'License fonts to this co-worker',
	buttonCancel: 'Go back without making changes',
	buttonContinue: 'Continue',
	info:
		'You can license a font for anyone within your organization. Fonts can’t be purchased for third parties, and cannot be purchased for resale. Font licenses are non-transferrable.',
	questions: 'Questions about',
	questionsLinkText: 'licensing'
};

/**
 * Favorites
 */
export const favorites = {
	emptyFavoritesHeader: 'You haven’t added any favorites yet.',
	emptyFavoritesHeaderTruncated: 'You have no favorites.',
	emptyFavoritesMessage: 'Here are some of our current favorites if you’re looking for fonts to add.',
	removedMessage: 'has been removed from your favorites.',
	removedMessageTruncated: 'Removed from favorites'
};

/**
 * Blog
 */
export const blog = {
	appendToSubject: ' | News from H%26Co',
	defaultTitle: 'News from H&Co',
	emailMsg: 'Something from H%26Co:%0A%0A',
	emailMsgEnd: '%0A%0A.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%20.%0A%0AFonts%20by%20Hoefler%26Co.%0Ahttp%3A%2F%2Fwww.typography.com'
};

/**
 * Suggested Pairings Component
 */
export const suggestedPairings = {
	header: 'Suggested Pairings:'
};

/**
 * Subscriptions Container
 */
export const subscriptions = {
	title: 'Manage Subscriptions',
	noSubscription: 'You do not current have a subscription.',
	modals: {
		app: {
			cancel: {
				title:
					'When would you prefer to end your {{subscription}}.typography service?',
				buttons: {
					danger: [
						'Let my subscription lapse on {{renewalDate}} ',
						'Terminate my license immediately'
					],
					cancel: 'Go back without making changes'
				}
			},
			deferredCancellation: {
				title: 'Allow your service to lapse?',
				message: `Your subscription will continue until {{renewalDate}},
					at which point your licenses to use H&Co fonts in apps,
					digital publications, and eBooks will terminate.`,
				buttons: {
					danger: 'Yes — allow my subscription to lapse',
					cancel: 'No — go back without making changes'
				}
			},
			immediateCancellation: {
				title: 'Cancel your service immediately?',
				message: `This will terminate your licenses to use H&Co fonts
					in apps, digital publications, and eBooks. This operation
					is final, and there are no refunds for unused service.`,
				buttons: {
					danger: 'Yes — cancel my service immediately',
					cancel: 'No — go back without making changes'
				}
			}
		},
		cloud: {
			cancel: {
				title:
					'When would you prefer to end your {{subscription}}.typography service?',
				buttons: {
					danger: [
						'Let my subscription lapse on {{renewalDate}} ',
						'Discontinue my service immediately'
					],
					cancel: 'Go back without making changes'
				}
			},
			deferredCancellation: {
				title: 'Allow your service to lapse?',
				message: `This way, your subscription will expire on
					{{renewalDate}} unless you renew it manually. At that time,
					both web font delivery and your license to use H&Co web fonts
					will terminate. Are you sure?`,
				buttons: {
					danger: 'Yes — allow my subscription to lapse',
					cancel: 'No — go back without making changes'
				}
			},
			immediateCancellation: {
				title: 'Cancel your service immediately?',
				message: `This will discontinue web font delivery right now.
					This operation is final, and there are no refunds for unused
					service. Would you like to continue?`,
				buttons: {
					danger: 'Yes — cancel my service immediately',
					cancel: 'No — go back without making changes'
				}
			},
			upgradeSettings: {
				title: 'Your {{subscription}} Subscription',
				button: 'Continue'
			}
		},
		enableAutoRenewal: {
			title:
				'Renew {{subscription}}.typography when it ends on {{renewalDate}}?',
			message: `Setting your subscription to automatically renew helps
				avoid service interruptions. We’ll email you a reminder one
				month before, in case you’d like to change or cancel your
				service.`,
			buttons: {
				secondary: 'Yes — set my subscription to auto-renew',
				cancel: 'No — go back without making changes'
			}
		},
		renewalSettings: {
			title:
				'On {{renewalDate}}, your {{subscription}}.typography subscription will renew for:',
			button: 'Save renewal settings'
		},
		upgradePayment: {
			title: 'Payment Method:',
			button: 'Place Order'
		},
		upgradeThanks: {
			title: 'Thank you!',
			message:
				'Your {{subscription}}.typography subscription has been upgraded. We’ve emailed you a receipt.',
			button: 'Done'
		},
		renewalThanks: {
			title: 'Thank you!',
			message:
				'Your {{subscription}}.typography subscription has been renewed. We’ve emailed you a receipt.',
			button: 'Done'
		},
		paymentMethod: {
			buttons: {
				secondary: 'Save and continue',
				cancel: 'Go back'
			}
		},
		transactionError: {
			title: 'Transaction Error',
			message:
				'We couldn’t process your credit card. Please check your credit card number and billing address, and try again.',
			button: 'Review payment details'
		},
		confirmCancelUpgrade: {
			title: 'Are you sure?',
			message:
				'Are you sure you wish to cancel with the upgrade process?',
			buttons: {
				secondary: 'Yes, I’m sure',
				cancel: 'No take me back to the upgrade process'
			}
		},
		appContact: {
			title: 'Update App.typography license',
			message:
				'App.typography licenses, including enterprise and more flexible licensing options, are available through our sales office by emailing licensing@typography.com.',
			button: 'Done'
		}
	}
};

/**
 * Support/FAQ
 */
export const support = {
	head: 'Frequently Asked Questions',
	subHead: 'Guided tours to introduce you <br />to working with fonts by H&Co.'
};

export const subscriptionSettings = {
	app: {
		license_term: {
			description: 'Apps, digital publications & eBook imprints',
			name: 'Titles',
			unit: 'year'
		},
		titleCount: {
			message:
				'You currently have {{titleCount}} active titles. Please remove some to renew at a lower level.'
		}
	},
	cloud: {
		extras: 'Subscription extras:',
		pageview: {
			description: 'for Production projects',
			name: 'Pageviews per month',
			message:
				'Please <a href="/contact">contact our sales office</a> to discuss large volume plans.',
			unit: 'pv/mo'
		},
		bandwidth: {
			description: 'for Development projects',
			name: 'Gigabytes per month',
			unit: 'gb/mo'
		},
		project: {
			description: 'Total Cloud.typography projects',
			name: 'Projects',
			unit: 'projects',
			message: 'You’re currently delivering {{projects}} projects.'
		}
	},
	upgrade: {
		total: 'Upgrade for '
	},
	optGroups: {
		current: 'Current',
		downgrade: 'Downgrade',
		upgrade: 'Upgrade'
	}
};

export const subscriptionUpgradePayment = {
	paymentMethod: {
		description: 'Please update your credit card information:',
		button: 'Change payment method'
	},
	app: {
		title: {
			description: 'For use with App.typography'
		}
	},
	cloud: {
		pageview: {
			description: 'Additional pageviews for Production projects'
		},
		bandwidth: {
			description: 'Additional bandwidth for Development projects'
		},
		project: {
			description: 'Up to {{quantity}} total Cloud.typography projects'
		}
	},
	order: {
		title: 'Your order:',
		button: 'Change quantities',
		pageviews: 'Additional Pageviews'
	},
	price: {
		subtotal: 'Subtotal',
		tax: 'Sales Tax',
		total: 'Total'
	}
};

export const subscriptionRenewals = {
	cloudExpired: {
		title: 'Renew Your Subscription',
		description:
			'Your Cloud.typography subscription has expired. To reactivate your Cloud Dashboard and any existing projects, please renew your subscription below.',
		button: 'Begin Checkout',
		pageview: {
			name: 'Cloud.typography Subscription',
			description: 'One-year web font subscription'
		}
	},
	paymentMethod: {
		title: 'Renew Your Subscription',
		description:
			'Your {{subscription}}.typography subscription wasn’t able to be renewed. <br/>Please update your credit card information and place your renewal order to ensure your service isn’t interrupted:',
		message:
			'Your card won’t be charged until you review and complete your order on the next screen.',
		button: 'Save and continue'
	},
	checkout: {
		title: 'Complete your order.',
		paymentMethod: {
			title: 'Payment details:',
			preLink: 'Change',
			link: 'payment method'
		},
		quantities: {
			title:
				'On {{renewalDate}}, your {{subscription}}.typography subscription will renew for:',
			link: 'Change quantities'
		},
		button: 'Place order',
		renewalSettings: {
			app: {
				titleCount: {
					description: 'for use with App.typography',
					unit: 'title',
					term: ' for ',
					termUnit: 'year'
				}
			},
			cloud: {
				pageview: {
					description: 'Monthly pageviews for Production projects',
					unit: 'pv/mo'
				},
				bandwidth: {
					description: 'Monthly bandwidth for Development projects',
					unit: 'gb/mo'
				},
				project: {
					description: 'Total Cloud.typography projects',
					unit: 'projects'
				}
			}
		}
	},
	renewalThanks: {
		title: 'Thanks for renewing your subscription',
		description: 'This is a placeholder'
	},
	price: {
		subtotal: 'Subtotal',
		tax: 'Sales Tax',
		total: 'Total'
	}
};

export const subscriptionAppTitles = {
	title: 'Add more titles',
	message:
		'If you know of the name of your app, digital publication, or eBook imprint, enter it here:',
	button: 'Continue',
	link: 'Add another title',
	placeholderName: 'Untitled',
	total: 'Upgrade for'
};

export const price = {
	period: {
		month: 'mo',
		year: 'year'
	}
};

export const whatsNew = {
	title: 'Latest Releases',
	linkText: 'See more'
};

export const modalPaymentMethod = {
	title: 'Payment Method',
	submitText: 'Save payment method',
	message:
		'Your card won’t be charged until you review and complete your order on the next screen.'
};

export const widgetAppTitle = {
	scopeTypes: {
		title: 'This title is…',
		undecided: 'I’ll decide later.'
	},
	name: {
		title: 'Name of'
	},
	placeholderName: 'Available'
};

export const siteMenu = {
	additionalSection: {
		title: 'Additional Categories'
	}
};

export const fontLibrary = {
	modals: {
		download: {
			title: 'Where will you be installing these fonts?',
			message: 'Need help getting started? You’ll find <a href="/faq/tagged/32">instructions for installing fonts</a> on Macs, Windows computers, and iOS devices in our FAQ.',
			buttons: {
				secondary: [
					'Download fonts for computers',
					'Download fonts for iOS devices'
				],
				cancel: 'Go back without downloading fonts'
			}
		},
		cancel: {
			title: 'When would you prefer to end your {{font}} self-hosted license?',
			buttons: {
				danger: [
					'Let my subscription lapse on {{renewalDate}} ',
					'Terminate my license immediately'
				],
				cancel: 'Go back without making changes'
			}
		},
		deferredCancellation: {
			title: 'Allow your service to lapse?',
			message: `Your license for these fonts will continue until {{renewalDate}},
				at which point it will be terminated.`,
			buttons: {
				danger: 'Yes — allow my subscription to lapse',
				cancel: 'No — go back without making changes'
			}
		},
		immediateCancellation: {
			title: 'Cancel your service immediately?',
			message: `Your license for these fonts will be terminated immediately.
				This operation is final, and there are no refunds for unused service.`,
			buttons: {
				danger: 'Yes — cancel my service immediately',
				cancel: 'No — go back without making changes'
			}
		},
		enableAutoRenewal: {
			title: 'Renew {{font}} self-hosted license when it ends on {{renewalDate}}?',
			message: `Setting your subscription to automatically renew helps
				avoid service interruptions. We’ll email you a reminder one
				month before, in case you’d like to change or cancel your
				service.`,
			buttons: {
				secondary: 'Yes — set my subscription to auto-renew',
				cancel: 'No — go back without making changes'
			}
		},
		upgradeSettings: {
			title: 'Your {{font}} self-hosted license',
			button: 'Continue'
		},
		renewalSettings: {
			title: 'On {{renewalDate}}, your {{font}} self-hosted license will renew for:',
			button: 'Save renewal settings'
		},
		upgradePayment: {
			title: 'Payment Method:',
			button: 'Place Order'
		},
		upgradeThanks: {
			title: 'Thank you!',
			message: 'Your {{font}} self-hosted license has been upgraded. We’ve emailed you a receipt.',
			button: 'Done'
		},
		paymentMethod: {
			buttons: {
				secondary: 'Save and continue',
				cancel: 'Go back'
			}
		},
		transactionError: {
			title: 'Transaction Error',
			message: 'We couldn’t process your credit card. Please check your credit card number and billing address, and try again.',
			button: 'Review payment details'
		},
		confirmCancelUpgrade: {
			title: 'Are you sure?',
			message: 'Are you sure you wish to cancel with the upgrade process?',
			buttons: {
				secondary: 'Yes, I’m sure',
				cancel: 'No take me back to the upgrade process'
			}
		}
	}
};

export const mailingListSubscribeUnsubscribe = {
	subscribe: {
		form: {
			title: 'Join our mailing list',
			label: 'Your email address:',
			button: 'Subscribe'
		},
		success: {
			title: 'Thanks! We’ll keep you posted.',
			button: 'Done'
		},
		error: {
			title: 'Error signing up',
			message: 'We weren’t able to add your address to our mailing list. Please try again, and if you’re still having trouble, [contact us.](/contact)'
		}
	},
	unsubscribe: {
		form: {
			title: 'Unsubscribe from our mailing list',
			label: 'Your email address:',
			button: 'Unsubscribe'
		},
		success: {
			title: 'Unsubscribe Successful',
			message: 'You’ve been unsubscribed from the H&Co mailing list. We’re sorry to see you go, but hope you’ll [keep in touch](/contact)!',
			button: 'Done'
		},
		error: {
			title: 'Error unsubscribing',
			message: 'We weren’t able to remove your address to our mailing list. Please try again, and if you’re still having trouble, [contact us](/contact).'
		}
	}
};

export const authentication = {
	intro: 'Introduce yourself!',
	favIntro: 'Save to your Favorites',
	removeFavIntro: 'Sign in to edit your Favorites',
	signin: 'Please sign in',
	accountMissingPassword: {
		buttonText: 'Done',
		message: 'We’ve sent an email to “`{{email}}`” with a link to finish setting up your account.',
		isAddingFavorite: `Before we can save this to your Wish List we need you to finish setting up your H&Co account. We’ve
			sent you an email to confirm your account ownership. Please follow the instructions in that email and
			then come back here to add this to your Wish List.`,
		title: 'Please check your email'
	}
};

export const finishSignup = {
	header: 'Complete your account setup',
	info: 'The complete your account setup link you used has expired.',
	button: 'Sign up'
};

export const resetPassword = {
	header: 'Choose a password',
	info: 'The password reset link you used has expired.',
	button: 'Save password'
};

export const gdpr = {
	checkboxLabel: 'Send me occasional email about new fonts, <br />design ideas, and techniques for typographers'
};

export default {
	account,
	app,
	blog,
	cart,
	checkout,
	siteMenu,
	favorites,
	form,
	licenseBar,
	licenseeModal,
	mailingListSubscribeUnsubscribe,
	packages,
	pageHeader,
	policies,
	price,
	socialMeta,
	styles,
	suggestedPairings,
	support,
	subscriptions,
	subscriptionSettings,
	subscriptionRenewals,
	subscriptionAppTitles,
	finishSignup,
	resetPassword
};
