import { get, merge } from 'lodash';
import cookie from 'react-cookie';
import { cookies, errorCodes } from 'config';

const REQUEST_DEFAULTS = {
	method: 'GET',
	credentials: 'include',
	headers: {
		Accept: 'application/json'
	}
};

const RESET_NEW_SESSION_TIMEOUT = 5000;
const METHODS_ALLOW_BODY = ['DELETE', 'PATCH', 'POST', 'PUT'];
const SESSION_ERRORS = [errorCodes.INVALID_TOKEN, errorCodes.MISSING_TOKEN];

let startNewSessionTask = null;

/**
 * Returns the current access token
 * @return {String} access token
 */
export function getAccessToken() {
	return cookie.load(cookies.accessToken);
}

/**
 * Sets the current session token cookie
 * @param {String} sessionToken
 */
function setSessionToken(sessionToken, options) {
	cookie.save(cookies.sessionToken, sessionToken, { ...options, path: '/', domain: '.typography.com', sameSite: 'lax' });
}

/**
 * Returns the current session token
 * @return {String} session token
 */
export function getSessionToken() {
	return cookie.load(cookies.sessionToken);
}

/**
 * Remove the current access token cookie
 * @return void
 */
export function removeSessionToken() {
	cookie.remove(cookies.sessionToken, { path: '/', domain: '.typography.com' });
}

/**
 * Get and assign a new session token
 * @return {Promise}			promise of new session attempt, non-error indicates success
 */
export async function startNewSession() {
	const sessionToken = await request('/api/session', { method: 'POST' });
	const expires = new Date();
	expires.setSeconds(expires.getSeconds() + sessionToken.data.expires_in);
	setSessionToken(sessionToken.data.session_id, { expires });

	return sessionToken;
}

/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed response with json as `data`
 */
async function parseJson(response) {
	return {
		headers: response.headers,
		status: response.status,
		statusText: response.statusText,
		data: await response.json()
	};
}

/**
 * Parses the text returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed response with text as `body`
 */
async function parseText(response) {
	return {
		headers: response.headers,
		status: response.status,
		statusText: response.statusText,
		body: await response.text()
	};
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {undefined} Returns undefined if assertion was successful
 */
function assertStatus(response) {
	if (response.status >= errorCodes.SUCCESS && response.status < errorCodes.REDIRECTION) {
		return;
	}

	const error = new Error(response.statusText);
	error.response = response;

	throw error;
}

function getUrlParameters(source, { includeEmpty = false }) {
	return Object
		.keys(source)
		.filter(k => includeEmpty || (typeof source[k] !== 'undefined' && source[k] !== null))
		.map(k => `${k}=${encodeURIComponent(source[k] || '')}`)
		.join('&');
}

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {Promise<object>}  An object containing the response data
 */
async function request(url, options = false) {
	const fetchOptions = merge({}, REQUEST_DEFAULTS, options);

	let fetchUrl = url;

	if (options.authenticate !== false) {
		const accessToken = getAccessToken();
		if (accessToken) {
			fetchOptions.headers['Authorization-API'] = `Bearer ${accessToken}`;
		}
	}

	if (typeof options.params === 'object' && fetchUrl.indexOf('?') === -1) {
		fetchUrl = `${url}?${getUrlParameters(options.params, { includeEmpty: options.includeEmpty === true || false })}`;
	}

	if (typeof options.body === 'object' && METHODS_ALLOW_BODY.includes(options.method)) {
		fetchOptions.headers['Content-Type'] = 'application/x-www-form-urlencoded';
		fetchOptions.body = getUrlParameters(options.body, {
			includeEmpty: options.includeEmpty === false ? options.includeEmpty : true
		});
	}

	const response = await fetch(fetchUrl, fetchOptions);

	let parsedResponse;
	try {
		parsedResponse = options.type === 'text'
			? await parseText(response)
			: await parseJson(response);
	} catch (err) {
		const error = new Error(`AsyncResponseParsingError: error parsing response from request to ${url} - ${err}`);
		throw error;
	}

	try {
		assertStatus(parsedResponse);
	} catch (err) {
		const errorCode = get(err, 'response.data.error.code');

		// isRetry ensures we only try again once
		if (SESSION_ERRORS.includes(errorCode) && !options.isRetry) {
			if (!startNewSessionTask) {
				startNewSessionTask = startNewSession();

				// reset the task variable so it's clear next time the session expires
				startNewSessionTask.then(() =>
					setTimeout(() => { startNewSessionTask = null; }, RESET_NEW_SESSION_TIMEOUT)
				);
			}

			await startNewSessionTask;

			return await request(url, { ...options, isRetry: true });
		}

		throw err;
	}

	return parsedResponse;
}

export default request;
