/*
 * LicensesReducer
 */
import {
	FOCUS_LICENSE,
	RESET_LICENSE,
	SELF_HOSTED_PLANS,
	SELF_HOSTED_PLANS_ERROR,
	SELF_HOSTED_PLANS_SUCCESS,
	SET_LICENSE,
	UPDATE_LICENSE
} from './constants';
import { fromJS } from 'immutable';
import { actionTypes } from 'redux-localstorage';
import camelcase from 'camelize';
import { get } from 'lodash';

// The initial state of the App
const initialState = fromJS({
	// License state for LicenseBar and all pricing
	licenses: {
		computer: {
			inCart: false,
			focused: false,
			value: 0
		},
		cloud: {
			inCart: false,
			focused: false,
			selfHosted: 0,
			subscribed: false
		},
		app: {
			inCart: false,
			focused: false,
			subscribed: false
		},
		selfHosted: {
			term: null,
			value: 0
		}
	},
	selfHostedPlans: null,
	selfHostedPlansLoading: false,
	selfHostedPlansError: false
});

function licensesReducer(state = initialState, action) {
	switch (action.type) {
		case actionTypes.INIT: {
			const persistedState = fromJS(get(action.payload, 'licenses.licenses', {}));
			return state
				.mergeDeep({ licenses: persistedState });
		}
		case SET_LICENSE: {
			return state
				.set({ licenses: action.data });
		}
		case UPDATE_LICENSE: {
			return state
				.mergeDeep({ licenses: action.data });
		}
		case RESET_LICENSE: {
			return state
				.set(initialState);
		}
		case FOCUS_LICENSE: {
			return state
				.mergeDeep({ licenses: action.data });
		}
		case SELF_HOSTED_PLANS:
			return state
				.set('selfHostedPlans', null)
				.set('selfHostedPlansLoading', true)
				.set('selfHostedPlansError', false);
		case SELF_HOSTED_PLANS_ERROR:
			return state
				.set('selfHostedPlans', null)
				.set('selfHostedPlansLoading', false)
				.set('selfHostedPlansError', action.error);
		case SELF_HOSTED_PLANS_SUCCESS:
			return state
				.set('selfHostedPlans', camelcase(action.data))
				.set('selfHostedPlansLoading', false)
				.set('selfHostedPlansError', false);
		default: return state;
	}
}

export default licensesReducer;
