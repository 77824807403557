import { blog as BlogLang } from 'language';


// These are the pages you can go to.
// They are all wrapped in the App component, which should contain the navbar etc
import { isAuthenticated, requiresReauthentication } from 'utils/authentication';
import { getAsyncInjectors } from './utils/asyncInjectors';

const requireAuthentication = (props, replace) => {
	if (isAuthenticated()) { return; }
	replace({ pathname: '/account/signin', state: { next: props.location.pathname } });
};

const requireReauthentication = (props, replace, callback) => {
	const handleReauthentication = () => {
		// Note: Not super proud of this. I'm not sure how else to send the user
		// back to the cart page (after reauth) to consolidate cart items.
		// Then, after reauth & cart consolidation, send him/her to checkout since
		// the user is already logged in. Tried via props.location.path = 'cart'
		// and was running into an infinite loop of:
		// cart > "Begin checkout" > reauth > cart > cart > cart > etc.
		// due to onEnter functionality of /checkout route.
		const nextpath = (props.location.pathname === '/checkout') ? '/cart' : props.location.pathname;
		replace({
			pathname: '/account/signin',
			state: {
				next: nextpath,
				forceAuthentication: true
			}
		});
	};

	if (!isAuthenticated()) {
		handleReauthentication();
		callback();
		return;
	}
	requiresReauthentication(
		{
			success: callback,
			error: () => {
				handleReauthentication();
				callback();
			}
		}
	);
};

/**
 * Require authentication if the user comes to the '/cart' route with “validate” in the URL. These
 * are generally users that received an email reminding them that they have items sitting in the
 * cart. We need them to log in so we can load their cart items.
 *
 * Example URL: https://www.typography.com/cart?validate=true&email=brandon%40smallparade.com
 *
 * @method  requireCartLogin
 * @param   {Object}  props  Route-related peoperties
 * @param   {Func}  replace  Fucntion to replace the current route with a new one
 * @return  {Void}
 */
const requireCartLogin = (props, replace, callback) => {
	const { email, validate } = props.location.query;

	// Exit process if the 'validate' query isn’t present.
	if (typeof validate === 'undefined') { callback(); return; }

	const handleCartLogin = () => {
		replace({
			pathname: '/account/signin',
			state: {
				email: typeof email !== 'undefined' ? email : null,
				forceAuthentication: true,
				next: '/cart'
			}
		});
	};

	if (!isAuthenticated()) {
		handleCartLogin();
		callback();
		return;
	}

	requireReauthentication(
		{
			success: callback,
			error: () => {
				handleCartLogin();
				callback();
			}
		}
	);
};

const errorLoading = (err) => {
	console.error('Dynamic page loading failed', err); // eslint-disable-line no-console
};

const loadModule = (cb) => (componentModule) => {
	cb(null, componentModule.default);
};

function createRoutes(store) {
	// create reusable async injectors using getAsyncInjectors factory
	const { injectReducer, injectSagas } = getAsyncInjectors(store);

	const routes = [{
		label: 'H&Co',
		path: '/',
		name: 'home',
		opensMenu: false,
		pageTitle: undefined,
		pageHeader: false,
		cjPageType: 'homepage',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/Homepage/reducer' /* webpackChunkName: "HomepageReducer" */),
				import('pages/Homepage/sagas' /* webpackChunkName: "HomepageSagas" */),
				import('pages/Homepage' /* webpackChunkName: "Homepage" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('homepage', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Homepage Test',
		path: '/homepage_test/:homepageSlug',
		name: 'home',
		opensMenu: false,
		pageTitle: undefined,
		pageHeader: false,
		cjPageType: 'homepage',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/Homepage/reducer' /* webpackChunkName: "HomepageReducer" */),
				import('pages/Homepage/sagas' /* webpackChunkName: "HomepageSagas" */),
				import('pages/Homepage' /* webpackChunkName: "Homepage" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('homepage', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Favorites',
		path: '/favorites',
		name: 'favorites',
		opensMenu: false,
		pageTitle: 'Favorites',
		pageHeader: {
			size: 'medium',
			title: 'Your Favorites'
		},
		footer: {
			step: 'cta'
		},
		cjPageType: 'accountCenter',
		getComponent(nextState, cb) {
			import('pages/Favorites' /* webpackChunkName: "Favorites" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'Page',
		path: '/info',
		name: 'Page',
		indexRoute: {
			onEnter: (nextState, replace) => replace('/') //redirect to home page, perhaps move this to server level redirect?
		},
		cjPageType: 'information',
		childRoutes: [
			{
				label: 'Page',
				path: '/info/:postSlug',
				name: 'page',
				pageTitle: undefined,
				pageHeader: {
					useLocal: true
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					const importModules = Promise.all([
						import('pages/Blog/reducer' /* webpackChunkName: "BlogReducer" */),
						import('pages/Blog/sagas' /* webpackChunkName: "BlogSagas" */),
						import('pages/Blog' /* webpackChunkName: "Blog" */)
					]);

					const renderRoute = loadModule(cb);

					importModules.then(([reducer, sagas, component]) => {
						injectReducer('blog', reducer.default);
						injectSagas(sagas.default);
						renderRoute(component);
					});

					importModules.catch(errorLoading);
				}
			}
		]
	}, {
		label: 'News',
		path: '/blog',
		name: 'blog',
		opensMenu: false,
		pageTitle: BlogLang.defaultTitle,
		pageHeader: {
			size: 'medium',
			useLocal: true
		},
		footer: {
			step: 'cta'
		},
		cjPageType: 'information',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/Blog/reducer' /* webpackChunkName: "BlogReducer" */),
				import('pages/Blog/sagas' /* webpackChunkName: "BlogSagas" */),
				import('pages/Blog' /* webpackChunkName: "Blog" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('blog', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		},
		childRoutes: [
			{
				label: 'Post',
				path: '/blog/:postSlug',
				name: 'post',
				pageTitle: undefined,
				pageHeader: {
					useLocal: true
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/Blog' /* webpackChunkName: "BlogPost" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
				label: 'Category',
				path: '/blog/column/:cat',
				name: 'category',
				pageTitle: undefined,
				pageHeader: {
					useLocal: true
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/Blog' /* webpackChunkName: "BlogCategory" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
				label: 'Tag',
				path: '/blog/tag/:tag',
				name: 'tag',
				pageTitle: undefined,
				pageHeader: {
					useLocal: true
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/Blog' /* webpackChunkName: "BlogTag" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}
		]
	}, {
		breadcrumbBar: true,
		label: 'Fonts',
		menuLevel: 1,
		name: 'fonts',
		opensMenu: true,
		pageHeader: false,
		pageTitle: 'Fonts',
		path: '/fonts',
		showMobile: true,
		cjPageType: 'productDetail',
		getComponent(nextState, cb) {
			import('pages/Fonts' /* webpackChunkName: "Fonts" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		},
		childRoutes: [
			{
				label: 'Gallery',
				path: '/fonts/:font/gallery',
				name: 'gallery',
				cjPageType: 'productDetail',
				onEnter: ({ location }, replace) => {
					replace(location.pathname.replace(/\/gallery/, ''));
				}
			}, {
				label: 'Overview',
				path: '/fonts/:font/overview',
				name: 'overview',
				includeInPageHeader: true,
				pageTitle: undefined,
				pageHeader: {
					size: 'large',
					useLocal: true
				},
				tertiaryMenu: 'font',
				cjPageType: 'productDetail',
				getComponent(nextState, cb) {
					import('pages/Overview' /* webpackChunkName: "Overview" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
				label: 'Browse by Style',
				path: '/fonts/styles',
				name: 'collections',
				includeInPageHeader: false,
				pageTitle: 'Fonts - Browse by Style',
				pageHeader: {
					size: 'simple',
					useLocal: true
				},
				cjPageType: 'category',
				getComponent(nextState, cb) {
					import('pages/Collections' /* webpackChunkName: "Collections" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				},
				childRoutes: [
					{
						label: 'Collection',
						path: '/fonts/styles/:collection',
						name: 'collection',
						pageTitle: undefined,
						pageHeader: {
							size: 'simple',
							useLocal: true
						},
						footer: {
							step: 'cta'
						},
						cjPageType: 'category',
						getComponent(nextState, cb) {
							import('pages/Collection' /* webpackChunkName: "Collection" */)
								.then(loadModule(cb))
								.catch(errorLoading);
						}
					}
				]
			}, {
				label: 'How to Use',
				path: '/fonts/:font/how-to-use',
				name: 'how-to-use',
				includeInPageHeader: true,
				scrollToTopOnSecondClick: true,
				tertiaryMenu: 'font',
				pageTitle: undefined,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				cjPageType: 'productDetail',
				getComponent(nextState, cb) {
					import('pages/HowToUse' /* webpackChunkName: "HowToUse" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
				label: 'Packages',
				path: '/fonts/:font/styles',
				name: 'styles',
				includeInPageHeader: true,
				tertiaryMenu: 'styles',
				pageTitle: undefined,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				cjPageType: 'productDetail',
				getComponent(nextState, cb) {
					import('pages/Styles' /* webpackChunkName: "Styles" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				},
				childRoutes: [
					{
						label: 'Package',
						path: '/fonts/:font/styles/:package',
						name: 'package',
						pageTitle: undefined,
						pageHeader: {
							size: 'small',
							useLocal: true
						},
						tertiaryMenu: 'styles',
						cjPageType: 'productDetail',
						getComponent(nextState, cb) {
							import('pages/Styles' /* webpackChunkName: "Package" */)
								.then(loadModule(cb))
								.catch(errorLoading);
						}
					}
				]
			}, {
				label: 'Design Notes',
				path: '/fonts/:font/design-notes',
				name: 'design-notes',
				includeInPageHeader: true,
				tertiaryMenu: 'font',
				pageTitle: undefined,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				cjPageType: 'productDetail',
				getComponent(nextState, cb) {
					const importModules = Promise.all([
						import('pages/DesignNotes/reducer' /* webpackChunkName: "DesignNotesReducer" */),
						import('pages/DesignNotes/sagas' /* webpackChunkName: "DesignNotesSagas" */),
						import('pages/DesignNotes' /* webpackChunkName: "DesignNotes" */)
					]);

					const renderRoute = loadModule(cb);

					importModules.then(([reducer, sagas, component]) => {
						injectReducer('designNotes', reducer.default);
						injectSagas(sagas.default);
						renderRoute(component);
					});

					importModules.catch(errorLoading);
				}
			}, {
				label: 'Characters',
				path: '/fonts/:font/characters',
				name: 'characters',
				includeInPageHeader: true,
				tertiaryMenu: 'font',
				pageTitle: undefined,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				cjPageType: 'productDetail',
				getComponent(nextState, cb) {
					import('pages/Characters' /* webpackChunkName: "Characters" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
			// 	label: 'Try',
			// 	path: 'https://try.typography.com/?font=', // '/fonts/:font/try',
			// 	name: 'try',
			// 	includeInPageHeader: true,
			// 	tertiaryMenu: 'font',
			// 	pageTitle: undefined,
			// 	pageHeader: {
			// 		size: 'small',
			// 		useLocal: true
			// 	}
			// }, {
				label: 'What’s New?',
				path: '/fonts/latest-releases',
				name: 'latest-releases',
				includeInPageHeader: false,
				pageHeader: {
					size: 'simple',
					useLocal: true
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'category',
				getComponent(nextState, cb) {
					const importModules = Promise.all([
						import('pages/LatestReleases/reducer' /* webpackChunkName: "LatestReleasesReducer" */),
						import('pages/LatestReleases/sagas' /* webpackChunkName: "LatestReleasesSagas" */),
						import('pages/LatestReleases' /* webpackChunkName: "LatestReleases" */)
					]);

					const renderRoute = loadModule(cb);

					importModules.then(([reducer, sagas, component]) => {
						injectReducer('latest-releases', reducer.default);
						injectSagas(sagas.default);
						renderRoute(component);
					});

					importModules.catch(errorLoading);
				}
			}, {
				label: 'Font',
				path: '/fonts/:font',
				name: 'font',
				includeInPageHeader: false,
				pageTitle: undefined,
				pageHeader: false,
				cjPageType: 'productDetail'
			}
		]
	}, {
		label: 'Collections Redirect',
		path: '/collections/:collection',
		name: 'collections-redirect',
		pageTitle: undefined,
		pageHeader: {
			size: 'simple',
			useLocal: true
		},
		footer: {
			step: 'cta'
		},
		cjPageType: 'category',
		onEnter: (props, replace) => {
			replace({ pathname: `/fonts/styles/${props.params.collection}` });
		}
	}, {
		breadcrumbBar: true,
		label: 'News',
		menuLevel: 1,
		name: 'news',
		opensMenu: false,
		pageTitle: 'News',
		path: '/news',
		pageHeader: {
			size: 'medium',
			title: BlogLang.defaultTitle,
			useLocal: true
		},
		showMobile: true,
		cjPageType: 'information',
		onEnter: (props, replace) => {
			replace({ pathname: '/blog' });
		}
	}, {
		breadcrumbBar: true,
		label: 'Discover',
		menuLevel: 1,
		name: 'discover',
		opensMenu: false,
		pageHeader: false,
		pageTitle: 'Discover',
		path: 'https://discover.typography.com/',
		showMobile: false,
		cjPageType: 'information'
	}, {
		breadcrumbBar: true,
		label: 'Try',
		menuLevel: 1,
		name: 'try',
		opensMenu: false,
		pageHeader: false,
		pageTitle: 'Try',
		path: 'https://try.typography.com/',
		showMobile: false
	}, {
		breadcrumbBar: true,
		label: 'Help',
		menuLevel: 1,
		name: 'help',
		opensMenu: true,
		pageHeader: false,
		pageTitle: 'Help',
		path: '/help',
		showMobile: true,
		cjPageType: 'information',
		onEnter: (props, replace) => {
			replace({ pathname: '/faq' });
		}
	}, {
		breadcrumbBar: true,
		footer: {
			step: 'cta'
		},
		label: 'About',
		menuLevel: 1,
		name: 'about',
		opensMenu: true,
		pageHeader: false,
		pageTitle: 'Meet H&Co',
		path: '/about-us',
		showMobile: true,
		cjPageType: 'information',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/AboutUs/reducer' /* webpackChunkName: "AboutUsReducer" */),
				import('pages/AboutUs/sagas' /* webpackChunkName: "AboutUsSagas" */),
				import('pages/AboutUs' /* webpackChunkName: "AboutUs" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('about-us', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Account',
		path: '/account',
		name: 'account',
		opensMenu: false,
		pageTitle: 'Account',
		pageHeader: false,
		onEnter: requireReauthentication,
		cjPageType: 'accountCenter',
		getComponent(nextState, cb) {
			import('pages/Account' /* webpackChunkName: "Account" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'Account Details',
		name: 'account-details',
		pageTitle: 'Account Details',
		pageHeader: {
			size: 'medium',
			title: 'Account Details'
		},
		path: '/account/details',
		cjPageType: 'accountCenter',
		onEnter: (props, replace, callback) => {
			// Hash fragment driven redirects on the JS side for when the
			// server can't complete the 301 to the end destination because
			// it cannot read hashes
			requireReauthentication(props, replace, callback);
			const { location } = props;
			switch (location.hash) {
				case '#subscriptions':
					replace('/account/subscriptions');
					break;
				case '#font-library':
					replace('/account/font-library');
					break;
				case '#past-orders':
					replace('/account/order-history');
					break;
			}
		},
		getComponent(nextState, cb) {
			import('pages/AccountDetails' /* webpackChunkName: "AccountDetails" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'Order History',
		path: '/account/order-history',
		name: 'order-history',
		pageTitle: 'Order History',
		pageHeader: {
			size: 'medium',
			title: 'Order History'
		},
		cjPageType: 'accountCenter',
		onEnter: requireReauthentication,
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/OrderHistory/reducer' /* webpackChunkName: "OrderHistoryReducer" */),
				import('pages/OrderHistory/sagas' /* webpackChunkName: "OrderHistorySagas" */),
				import('pages/OrderHistory' /* webpackChunkName: "OrderHistory" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('order-history', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Order Receipt',
		path: '/account/order-history/:receiptID',
		name: 'order-receipt',
		pageTitle: 'Receipt',
		pageHeader: {
			size: 'medium',
			title: 'Order Receipt'
		},
		cjPageType: 'accountCenter',
		onEnter: requireReauthentication,
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/OrderReceipt/reducer' /* webpackChunkName: "OrderReceiptReducer" */),
				import('pages/OrderReceipt/sagas' /* webpackChunkName: "OrderReceiptSagas" */),
				import('pages/OrderReceipt' /* webpackChunkName: "OrderReceipt" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('order-receipt', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Font Library',
		path: '/account/font-library',
		name: 'font-library',
		pageTitle: 'Font Library',
		pageHeader: {
			size: 'medium',
			title: 'Your Font Library'
		},
		cjPageType: 'accountCenter',
		onEnter: requireReauthentication,
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/FontLibrary/reducer' /* webpackChunkName: "FontLibraryReducer" */),
				import('pages/FontLibrary/sagas' /* webpackChunkName: "FontLibrarySagas" */),
				import('pages/FontLibrary' /* webpackChunkName: "FontLibrary" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('font-library', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Subscriptions',
		path: '/account/subscriptions',
		name: 'subscriptions',
		pageTitle: 'Subscriptions',
		pageHeader: {
			size: 'medium',
			title: 'Manage Subscriptions'
		},
		cjPageType: 'accountCenter',
		onEnter: requireReauthentication,
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/Subscriptions/reducer' /* webpackChunkName: "SubscriptionsReducer" */),
				import('pages/Subscriptions/sagas' /* webpackChunkName: "SubscriptionsSagas" */),
				import('pages/Subscriptions' /* webpackChunkName: "Subscriptions" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('subscriptions', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'SignIn',
		path: '/account/signin',
		name: 'signin',
		pageTitle: 'Introduce yourself!',
		pageHeader: {
			size: 'simple',
			title: ''
		},
		cjPageType: 'accountCenter',
		getComponent(nextState, cb) {
			import('pages/SignIn' /* webpackChunkName: "SignIn" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'SignOut',
		path: '/account/signout',
		name: 'signout',
		pageTitle: undefined,
		pageHeader: false,
		cjPageType: 'accountCenter',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/SignOut/sagas' /* webpackChunkName: "SignOutSagas" */),
				import('pages/SignOut' /* webpackChunkName: "SignOut" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([sagas, component]) => {
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Reset Password',
		path: '/account/reset-password',
		name: 'reset-password',
		pageTitle: 'Reset Password',
		pageHeader: {
			size: 'simple',
			title: ''
		},
		cjPageType: 'accountCenter',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/FinishSignupResetPassword/reducer' /* webpackChunkName: "FinishSignupResetPasswordReducer" */),
				import('pages/FinishSignupResetPassword/sagas' /* webpackChunkName: "FinishSignupResetPasswordSagas" */),
				import('pages/FinishSignupResetPassword' /* webpackChunkName: "FinishSignupResetPassword" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('finish-signup-reset-password', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Complete your account setup',
		path: '/account/finish-signup',
		name: 'finish-signup',
		pageTitle: 'Complete your account setup',
		pageHeader: {
			size: 'simple',
			title: ''
		},
		cjPageType: 'accountSignup',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/FinishSignupResetPassword/reducer' /* webpackChunkName: "FinishSignupResetPasswordReducer" */),
				import('pages/FinishSignupResetPassword/sagas' /* webpackChunkName: "FinishSignupResetPasswordSagas" */),
				import('pages/FinishSignupResetPassword' /* webpackChunkName: "FinishSignupResetPassword" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('finish-signup-reset-password', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Subscriptions Renewals',
		path: '/account/renew/:subscription',
		name: 'subscription-renewal',
		pageTitle: 'Subscriptions Renewals',
		pageHeader: {
			useLocal: true
		},
		cjPageType: 'accountCenter',
		onEnter: requireReauthentication,
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/Subscriptions/reducer' /* webpackChunkName: "SubscriptionsReducer" */),
				import('pages/Subscriptions/sagas' /* webpackChunkName: "SubscriptionsSagas" */),
				import('pages/SubscriptionRenewals' /* webpackChunkName: "SubscriptionRenewals" */)
			]);
			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('subscriptions', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Policies',
		path: '/policies',
		name: 'policies',
		pageTitle: undefined,
		pageHeader: {
			size: 'small',
			useLocal: true
		},
		footer: {
			step: 'cta'
		},
		cjPageType: 'information',
		getComponent(nextState, cb) {
			import('pages/Policies' /* webpackChunkName: "Policies" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		},
		childRoutes: [
			{
				label: 'EULA',
				path: '/policies/eula',
				name: 'eula',
				pageTitle: 'End-User License Agreement',
				includeInPageHeader: true,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/Policy' /* webpackChunkName: "EULA" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
				label: 'Web Fonts',
				path: '/policies/web-fonts-terms',
				name: 'web-fonts-terms',
				pageTitle: 'Web Fonts License Agreement',
				includeInPageHeader: true,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/Policy' /* webpackChunkName: "CloudTerms" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
				label: 'Cloud.typography',
				path: '/policies/cloud-terms',
				name: 'cloud-terms',
				pageTitle: 'Cloud.typography Terms of Service',
				includeInPageHeader: true,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/Policy' /* webpackChunkName: "CloudTerms" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
				label: 'App.typography',
				path: '/policies/app-terms',
				name: 'app-terms',
				pageTitle: 'App.typography Terms of Service',
				includeInPageHeader: true,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/Policy' /* webpackChunkName: "AppTerms" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
				label: 'Privacy',
				path: '/policies/privacy',
				name: 'privacy',
				pageTitle: 'Privacy Policy',
				includeInPageHeader: true,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/Policy' /* webpackChunkName: "Privacy" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}, {
				label: 'Site Terms',
				path: '/policies/terms',
				name: 'terms',
				pageTitle: 'Site Terms',
				includeInPageHeader: true,
				pageHeader: {
					size: 'small',
					useLocal: true
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/Policy' /* webpackChunkName: "SiteTerms" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}
		]
	}, {
		label: 'Cart',
		path: '/cart',
		name: 'cart',
		pageTitle: 'Your Cart',
		pageHeader: {
			size: 'medium',
			title: 'Your Cart'
		},
		cjPageType: 'cart',
		getComponent(nextState, cb) {
			import('pages/Cart' /* webpackChunkName: "Cart" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		},
		onEnter: requireCartLogin
	}, {
		label: 'Checkout',
		path: '/checkout',
		name: 'checkout',
		onEnter: requireReauthentication,
		pageTitle: 'Checkout',
		pageHeader: {
			size: 'medium',
			useLocal: true
		},
		cjPageType: 'cart',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/Checkout/reducer' /* webpackChunkName: "CheckoutReducer" */),
				import('pages/Checkout/sagas' /* webpackChunkName: "CheckoutSagas" */),
				import('pages/Checkout' /* webpackChunkName: "Checkout" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('checkout', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Checkout Thank You',
		path: '/checkout/:orderId',
		name: 'checkout-thank-you',
		onEnter: requireReauthentication,
		pageTitle: 'Checkout Thank You',
		pageHeader: {
			size: 'simple',
			useLocal: true
		},
		licenseBarIsVisible: false,
		cjPageType: 'conversionConfirmation',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/CheckoutThankYou/reducer' /* webpackChunkName: "CheckoutThankYouReducer" */),
				import('pages/CheckoutThankYou/sagas' /* webpackChunkName: "CheckoutThankYouSagas" */),
				import('pages/CheckoutThankYou' /* webpackChunkName: "CheckoutThankYou" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('checkoutThankYou', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		}
	}, {
		label: 'Dashboard',
		path: '/dashboard',
		name: 'dashboard',
		pageTitle: 'Cloud.typography Dashboard',
		pageHeader: false,
		onEnter: requireAuthentication,
		cjPageType: 'accountCenter',
		getComponent(nextState, cb) {
			import('pages/Dashboard' /* webpackChunkName: "Dashboard" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'Usage reports',
		path: '/usage-reports',
		name: 'usage-reports',
		pageTitle: 'Cloud.typography Usage reports',
		pageHeader: false,
		onEnter: requireAuthentication,
		cjPageType: 'accountCenter',
		getComponent(nextState, cb) {
			import('pages/UsageReports' /* webpackChunkName: "UsageReport" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'User Guide',
		path: '/user-guide',
		name: 'user-guide',
		pageTitle: undefined,
		cjPageType: 'information',
		getComponent(nextState, cb) {
			import('pages/CloudUserGuide' /* webpackChunkName: "CloudUserGuide" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		},
		childRoutes: [
			{
				label: 'User Guide',
				path: '/user-guide/:deeplink',
				name: 'user-guide-deeplink',
				pageTitle: undefined,
				cjPageType: 'information',
				onEnter: ({ params }, replace) => {
					const legitDeepLinks = [
						'included',
						'creating-projects',
						'using-projects',
						'choosing-fonts',
						'font-styles',
						'font-features',
						'font-tools',
						'legacy-support',
						'managing-domains',
						'development-and-production',
						'moving-to-production',
						'project-tools',
						'fonts-in-email',
						'best-practices'
					];

					if (legitDeepLinks.indexOf(params.deeplink) < 0) {
						replace('/user-guide');
					}
				},
				getComponent(nextState, cb) {
					import('pages/CloudUserGuide' /* webpackChunkName: "CloudUserGuide" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}
		]
	}, {
		label: 'FAQ',
		path: '/faq',
		name: 'faq-topics',
		pageTitle: 'Frequently Asked Questions',
		pageHeader: {
			size: 'simple',
			useLocal: true
		},
		footer: {
			step: 'cta'
		},
		cjPageType: 'information',
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('pages/FAQ/reducer' /* webpackChunkName: "FAQReducer" */),
				import('pages/FAQ/sagas' /* webpackChunkName: "FAQSagas" */),
				import('pages/FAQ' /* webpackChunkName: "FAQ" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([reducer, sagas, component]) => {
				injectReducer('faq', reducer.default);
				injectSagas(sagas.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		},
		childRoutes: [
			{
				label: 'FAQ',
				path: '/faq/tagged/:tagID',
				name: 'faq-tag',
				pageTitle: 'Frequently Asked Questions',
				pageHeader: {
					size: 'simple'
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/FAQ' /* webpackChunkName: "FAQTag" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			},
			{
				label: 'FAQ',
				path: '/faq/:faqID',
				name: 'faq',
				pageTitle: 'Frequently Asked Questions',
				pageHeader: {
					size: 'simple'
				},
				footer: {
					step: 'cta'
				},
				cjPageType: 'information',
				getComponent(nextState, cb) {
					import('pages/FAQ' /* webpackChunkName: "FAQID" */)
						.then(loadModule(cb))
						.catch(errorLoading);
				}
			}
		]
	}, {
		label: 'Contact',
		path: '/contact',
		name: 'contact',
		pageTitle: 'Contact Us',
		pageHeader: {
			size: 'simple',
			title: ''
		},
		footer: {
			step: 'cta'
		},
		cjPageType: 'information',
		getComponent(nextState, cb) {
			import('pages/Contact' /* webpackChunkName: "Contact" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'Webfonts',
		path: '/webfonts',
		name: 'webfonts',
		pageTitle: 'Cloud & Self Hosted',
		pageHeader: false,
		cjPageType: 'information',
		getComponent(nextState, cb) {
			import('pages/Webfonts' /* webpackChunkName: "Webfonts" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'Search',
		path: '/search',
		name: 'search',
		pageTitle: 'Search',
		pageHeader: {
			size: 'medium',
			title: 'Search',
			useLocal: true
		},
		cjPageType: 'searchResults',
		getComponent(nextState, cb) {
			import('pages/Search' /* webpackChunkName: "Search" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'Join our mailing list',
		path: '/subscribe',
		name: 'subscribe',
		pageTitle: 'Join our mailing list',
		pageHeader: {
			size: 'simple',
			title: ''
		},
		cjPageType: 'information',
		getComponent(nextState, cb) {
			import('pages/MailingListSubscribeUnsubscribe' /* webpackChunkName: "Search" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: 'Unsubscribe from our mailing list',
		path: '/unsubscribe',
		name: 'unsubscribe',
		pageTitle: 'Unsubscribe from our mailing list',
		pageHeader: {
			size: 'simple',
			title: ''
		},
		cjPageType: 'information',
		getComponent(nextState, cb) {
			import('pages/MailingListSubscribeUnsubscribe' /* webpackChunkName: "Search" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		label: '404',
		path: '/404',
		name: '404',
		cjPageType: 'information',
		getComponent(nextState, cb) {
			import('pages/NotFoundPage' /* webpackChunkName: "404" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}, {
		path: '*',
		name: 'notfound',
		pageTitle: undefined,
		pageHeader: false,
		cjPageType: 'information',
		getComponent(nextState, cb) {
			import('pages/NotFoundPage' /* webpackChunkName: "404" */)
				.then(loadModule(cb))
				.catch(errorLoading);
		}
	}];

	return routes;
}

export default function createRootComponent(store) {
	const { injectSagas } = getAsyncInjectors(store);
	return {
		getComponent(nextState, cb) {
			const importModules = Promise.all([
				import('store/account/sagas' /* webpackChunkName: "AccountSagas" */),
				import('store/fonts/sagas' /* webpackChunkName: "FontSagas" */),
				import('store/licenses/sagas' /* webpackChunkName: "LicensesSagas" */),
				import('store/authentication/sagas' /* webpackChunkName: "AuthSagas" */),
				import('pages/App/sagas' /* webpackChunkName: "AppSagas" */),
				import('pages/App' /* webpackChunkName: "App" */)
			]);

			const renderRoute = loadModule(cb);

			importModules.then(([account, fonts, licenses, authentication, app, component]) => {
				injectSagas(account.default);
				injectSagas(fonts.default);
				injectSagas(licenses.default);
				injectSagas(authentication.default);
				injectSagas(app.default);
				renderRoute(component);
			});

			importModules.catch(errorLoading);
		},
		childRoutes: createRoutes(store)
	};
}
