import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import styles from './styles.css';
class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: null,
			info: null
		};
	}

	close = () => {
		this.setState({ error: null });
	}

	componentDidCatch(error, info) {
		this.setState({ error, info });
		if (process.env.NODE_ENV === 'development' && process.env.LOG_ERRORS) {
			throw error;
		} else {
			Sentry.withScope(scope => {
				scope.setExtra('debug', false);
				Sentry.captureException(error);
			});
		}
	}

	render() {
		return (
			<Fragment>
				{ this.state.error && process.env.NODE_ENV === 'development' &&
					<section className={ styles.wrapper }>
						<button className={ styles.close } onClick={ this.close }>Close</button>
						<h1>Error</h1>
						<h2>{ this.state.error.toString() }</h2>
						<div className={ styles.errorPane }>
							<pre className={ styles.pre }>
								<code className={ styles.code }>
									{ this.state.info.componentStack.replace(/\s{5}/g, '\n').replace(/\n/, '') }
								</code>
							</pre>
						</div>
					</section>
				}
				{ this.props.children }
			</Fragment>
		);
	}
}

ErrorBoundary.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.node,
		PropTypes.arrayOf(PropTypes.node)
	]).isRequired
};

export default ErrorBoundary;
