// Load all products
export const LOAD_PRODUCTS = 'hco/Fonts/LOAD_PRODUCTS';
export const LOAD_PRODUCTS_SUCCESS = 'hco/Fonts/LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCTS_ERROR = 'hco/Fonts/LOAD_PRODUCTS_ERROR';

// Load selected product styles
export const LOAD_PRODUCT_STYLES = 'hco/Fonts/LOAD_PRODUCT_STYLES';
export const LOAD_PRODUCT_STYLES_SUCCESS = 'hco/Fonts/LOAD_PRODUCT_STYLES_SUCCESS';
export const LOAD_PRODUCT_STYLES_ERROR = 'hco/Fonts/LOAD_PRODUCT_STYLES_ERROR';

// Set the product line so we can show related information
export const SET_PRODUCT_LINE = 'hco/Fonts/SET_PRODUCT_LINE';
export const CLEAR_PRODUCT_LINE = 'hco/Fonts/CLEAR_PRODUCT_LINE';

// Load font packages
export const LOAD_PACKAGES = 'hco/Fonts/LOAD_PACKAGES';
export const LOAD_PACKAGES_SUCCESS = 'hco/Fonts/LOAD_PACKAGES_SUCCESS';
export const LOAD_PACKAGES_ERROR = 'hco/Fonts/LOAD_PACKAGES_ERROR';
export const CLEAR_PACKAGES = 'hco/Fonts/CLEAR_PACKAGES';

// Load font characters
export const LOAD_CHARACTERS = 'hco/Fonts/LOAD_CHARACTERS';
export const LOAD_CHARACTERS_SUCCESS = 'hco/Fonts/LOAD_CHARACTERS_SUCCESS';
export const LOAD_CHARACTERS_ERROR = 'hco/Fonts/LOAD_CHARACTERS_ERROR';
export const CLEAR_CHARACTERS = 'hco/Fonts/CLEAR_CHARACTERS';
export const SET_CHARACTERS = 'hco/Fonts/SET_CHARACTERS';

// Get special offers for selected product line
export const GET_SPECIAL_OFFERS = 'hco/Fonts/GET_SPECIAL_OFFERS';
export const GET_SPECIAL_OFFERS_SUCCESS = 'hco/Fonts/GET_SPECIAL_OFFERS_SUCCESS';
export const GET_SPECIAL_OFFERS_ERROR = 'hco/Fonts/GET_SPECIAL_OFFERS_ERROR';

// Set the range slider resize data for a one-liner
export const SET_RESIZE_SETTINGS = 'hco/Fonts/SET_RESIZE_SETTINGS';
export const SET_RESIZE_SETTINGS_SUCCESS = 'hco/Fonts/SET_RESIZE_SETTINGS_SUCCESS';
export const SET_RESIZE_SETTINGS_ERROR = 'hco/Fonts/SET_RESIZE_SETTINGS_ERROR';
export const CLEAR_RESIZE_SETTINGS = 'hco/Fonts/CLEAR_RESIZE_SETTINGS';

// Load gallery
export const LOAD_GALLERY = 'hco/Fonts/LOAD_GALLERY';
export const LOAD_GALLERY_SUCCESS = 'hco/Fonts/LOAD_GALLERY_SUCCESS';
export const LOAD_GALLERY_ERROR = 'hco/Fonts/LOAD_GALLERY_ERROR';
export const CLEAR_GALLERY = 'hco/Fonts/CLEAR_GALLERY';

// Load How to Use
export const LOAD_HOW_TO_USE = 'hco/Fonts/LOAD_HOW_TO_USE';
export const LOAD_HOW_TO_USE_SUCCESS = 'hco/Fonts/LOAD_HOW_TO_USE_SUCCESS';
export const LOAD_HOW_TO_USE_ERROR = 'hco/Fonts/LOAD_HOW_TO_USE_ERROR';
export const CLEAR_HOW_TO_USE = 'hco/Fonts/CLEAR_HOW_TO_USE';

// Load one-liners
export const LOAD_ONELINERS = 'hco/Fonts/LOAD_ONELINERS';
export const LOAD_ONELINERS_SUCCESS = 'hco/Fonts/LOAD_ONELINERS_SUCCESS';
export const LOAD_ONELINERS_ERROR = 'hco/Fonts/LOAD_ONELINERS_ERROR';

// Get package styles
export const GET_PACKAGE_STYLES_SUCCESS = 'hco/Fonts/GET_PACKAGE_STYLES_SUCCESS';
export const GET_PACKAGE_STYLES_ERROR = 'hco/Fonts/GET_PACKAGE_STYLES_ERROR';

// Get suggested pairings
export const LOAD_SUGGESTED_PAIRINGS = 'hco/Fonts/LOAD_SUGGESTED_PAIRINGS';
export const LOAD_SUGGESTED_PAIRINGS_SUCCESS = 'hco/Fonts/LOAD_SUGGESTED_PAIRINGS_SUCCESS';
export const LOAD_SUGGESTED_PAIRINGS_ERROR = 'hco/Fonts/LOAD_SUGGESTED_PAIRINGS_ERROR';

// Download Font Package
export const DOWNLOAD_FONT_PACKAGE = 'hco/Fonts/DOWNLOAD_FONT_PACKAGE';
export const DOWNLOAD_FONT_PACKAGE_SUCCESS = 'hco/Fonts/DOWNLOAD_FONT_PACKAGE_SUCCESS';
export const DOWNLOAD_FONT_PACKAGE_ERROR = 'hco/Fonts/DOWNLOAD_FONT_PACKAGE_ERROR';

// Load TRY layout
export const LOAD_TRY_LAYOUT = 'hco/Fonts/LOAD_TRY_LAYOUT';
export const LOAD_TRY_LAYOUT_SUCCESS = 'hco/Fonts/LOAD_TRY_LAYOUT_SUCCESS';
export const LOAD_TRY_LAYOUT_ERROR = 'hco/Fonts/LOAD_TRY_LAYOUT_ERROR';
export const CLEAR_TRY_LAYOUT = 'hco/Fonts/CLEAR_TRY_LAYOUT';

// Font related helpers
export const GET_STYLE_BY_ID = 'hco/Fonts/GET_STYLE_BY_ID';

// Overview editor controls
export const SET_CONTROLS_OPEN = 'hco/Fonts/SET_CONTROLS_OPEN';
