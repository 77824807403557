/* eslint-disable no-magic-numbers */
import numeral from 'numeral';
import FontFaceObserver from 'fontfaceobserver';
import * as Sentry from '@sentry/browser';

import {
	capitalize,
	each,
	filter,
	find,
	findKey,
	forOwn,
	get,
	isArray,
	isEmpty,
	isNumber,
	zipObject
} from 'lodash';

import { numValues as NumValues } from 'pages/Overview/config';

/**
 * Replace segment
 *
 * Replace the last segment in a URL. Redux has problems with relative URLs, so
 * this function helps in creating a URL for you when moving to an adjacent segment.
 *
 * @param  {String} pathname
 * @param  {String} newSegment
 * @return {String}
 */
export function replaceLastSegment(pathname, newSegment) {
	let currentURL = pathname.split('/');
	currentURL.pop();
	currentURL = currentURL.join('/');
	return `${currentURL}/${newSegment}`;
}


/**
 * Get selected licenses
 *
 * Licenses and their values are stored as a complex object in Redux. There
 * isn't currently a built-in way to retrieve the currently selected license, so
 * we use this utility function instead
 *
 * @param  {Object} licenses	Object with licenses and their values
 * @return {Boolean}
 */
export function getFocusedLicense(licenses) {
	return findKey(licenses, license => license.focused);
}


/**
 * Generate the license string based on the selected licenses in the store.
 *
 * @method generateLicensePhrase
 * @param  {Object} options Object containing current state of user’s licenses
 * @return {String} Formatted string (i.e. “2 computers +web +app”)
 */
export function generateLicensePhrase(options) {
	const parts = [];

	// Add prefix first if it exists
	if (options.prefix) { parts.push(options.prefix); }

	// Add count if passed in as an option
	// Special Offers found on the fonts pages: overview, styles, design notes,
	// How to Use, and Characters, require a display of count and label
	// whereas Cart page utiltize just the label. If we inject computer count into
	// all generateLicensePhrase, the function 'selectOptions' (located in this
	// file) will need to strip them out again, so we're adding an optional
	// parameter called 'computerCount' if count is desired from the
	// generated phrase.
	if (options.computerCount) {
		parts.push(options.computerCount);
	}

	// Add computer label if it exists
	if (options.computers) {
		if (!options.computerCount) {
			parts.push(options.computers);
		}

		const computerCount = (options.computers > 1) ? 'Computers' : 'Computer';
		parts.push(computerCount);
	}

	// Add apps if it exists
	if (options.apps && parts.length >= 1) {
		const prefix = parts.length >= 1 ? ' + ' : '';
		parts.push(`${prefix} Apps`);
	}

	// If self-hosted is passed in as an object we’re looking for the LicenseBar message
	if (options.selfHosted && isNaN(options.selfHosted) && options.selfHosted.pageviews) {
		const prefix = parts.length >= 1 ? ' + ' : '';
		const pageviews =
			options.selfHosted.pageviews >= 1000000
				? `${options.selfHosted.pageviews / 1000000} million pv/mo,`
				: `${numeral(options.selfHosted.pageviews).format('0,0')} pv/mo,`;
		const value =
			options.selfHosted.pageviews === 0
				? 'Other plan options'
				: pageviews;
		const term =
			options.selfHosted.term === 'annual'
				? 'for one year'
				: 'perpetually';
		parts.push(`${prefix}Websites <small>with ${value} self-hosted ${term}</small>`);
	}

	// Otherwise if self-hosted is passed in as a number we’re looking for the SpecialOffer message
	if (options.selfHosted && !isNaN(options.selfHosted)) {
		if (parts.length) { parts.push('&'); }
		parts.push('Websites');
	}

	return parts.join(' ');
}


/**
 * Check to see if a license has been selected
 *
 * @method licenseIsPopulated
 * @param  {object} licenses Object containing current state of user’s licenses
 * @return {String} Formatted string (i.e. “2 computers +web +app”)
 */
export function licenseIsPopulated(licenses) {
	const activeLicenses = filter(licenses, item => (isNumber(item.value) && item.value > 0) || item.inCart || item.subscribed);
	return activeLicenses.length > 0;
}

/**
 * Click outside event
 *
 * @method clickOutside
 * @param  {Object} event The event directly from the clicked element
 * @param  {Node} safeElement The element we’re targetting for the `clickOutside` event
 * @return {Boolean}
 */
export function clickOutside(event, safeElement) {
	if (!safeElement) { return false; }

	const isInside = find(safeElement, element =>
		element && element.contains(event.target)
	);

	return !isInside;
}


/**
 * Generate CPU options for the select menu
 *
 * @method  selectOptions
 * @param   {String}  values   Array of values for the select menu, pulled from config
 * @param   {String}  type     License type generate options for
 * @param   {Array}   message  Exploded version of message parts to use for the label
 * @return  {Array}   		   Array of key/value pairs to use for the select menu
 */
export function selectOptions(values) {
	return values.map((value, index) => {
		let label;
		if (index === 0) {
			return {};
		} else if (index === values.length - 1) {
			label = 'More than 200 computers';
		} else {
			const pluralizeMessage = (value > 1) ? 'Computers' : 'Computer';

			label = `${value} ${pluralizeMessage}`;
		}
		return { value, label };
	});
}


/**
 * Generate description line for cart item
 *
 * @method  selectOptions
 * @param   {String}  values   Array of values for the select menu, pulled from config
 * @param   {String}  type     License type generate options for
 * @param   {Array}   message  Exploded version of message parts to use for the label
 * @param   {Boolean} message  Is the subscription annual or perpetual (applicable onlu to self-hosted)
 * @return  {Array}   		   Array of key/value pairs to use for the select menu
 */
export function generateDescription(type, quantity, inCart, isAnnual) {
	const HCo = require('config'); // eslint-disable-line global-require
	const Lang = require('language'); // eslint-disable-line global-require
	const { pope } = require('pope'); // eslint-disable-line global-require
	const numberToWord = capitalize(HCo.numbers[quantity]);

	switch (type) {
		case 'self-hosted':
			return isAnnual
				? 'Self-hosted web fonts *for one year*'
				: 'Self-hosted web fonts';
		case 'app':
		case 'cloud':
			return pope(Lang.cart.licenses[type].description, { term: numberToWord });
		case 'webfont':
			return pope(Lang.cart.licenses.webfont.description);
		case 'discount':
		case 'computer':
			return null;
		default:
			return pope(Lang.cart.licenses[type].description, { term: numberToWord });
	}
}


/**
 * Determine whether or not we should show the SpecialOffer bug based on the user’s license selection.
 *
 * @method  shouldHideSpecialOffer
 * @param   {Object}  licenses  The current state of licenses
 * @return  {Boolean}
 */
export function shouldHideSpecialOffer(licenses) {
	return licenses.selfHosted.value > 0 && licenses.computer.value === 0;
}


/**
 * Get the first letter of a string, skipping "The".
 *
 * @method  getFirstLetter
 * @param   {String}  str  The string to parse for the letter
 * @return  {String}  The first letter
 */
export function getFirstLetter(str) {
	if (!str) { return null; }
	const cleanString = str.replace(/^The\s/g, '');
	return cleanString.substring(0, 1);
}


export function disableBodyScrolling() {
	document.body.classList.add('disableScrolling');
}


export function enableBodyScrolling() {
	document.body.classList.remove('disableScrolling');
}

/**
 * Create ModuleStyleSwitcher options object
 *
 * @param  {Array} options Array of options as they come in from the API
 * @param  {String} key    String identifier for that set of options
 * @return {Object}        Options object formatted for ModuleStyleSwitcher
 */
export function makeModuleBInteractive5by7(group, groupID) {
	const values = group.map(toggle => {
		const { value, labelDesktop, labelMobile } = toggle;
		const label = labelMobile === '' ? labelDesktop : labelMobile;
		return {
			groupID, labelDesktop, label, value
		};
	});

	// let siblings = filter(group, toggle => toggle.siblings !== '').map(toggle => {
	let siblings = group.map(toggle => {
		// Siblings come in as a string like `"3"` or `"3,1"`. Here we
		// split them into an array and convert each value to a number.
		const siblingIds = toggle.siblings.split(',').map(sibling => parseInt(sibling, 10) || 0);

		siblingIds.unshift(toggle.id); // Do we need this? It isn’t assigned to anything.

		// Find the toggles with IDs that are included in the
		// siblingIds array.
		return group.filter(opt => siblingIds.includes(opt.id)).map(opt => opt.value);
	});

	siblings = siblings.length > 0 ? zipObject(group.map(option => option.value), siblings) : null;

	const defaults = filter(group, { default: 1 }).map(option => option.value);

	return { values, siblings, default: defaults };
}

/**
 * Cleans entries for passing to the ModuleAChart component
 *
 * @param  {Array} content     Entry group content
 * @param  {String} bgColorOdd  Odd background color
 * @param  {String} bgColorEven Event background color
 * @return {Object}             Cleaned table data
 */
export function cleanTableData(content, bgColorOdd, bgColorEven) {
	const tableObj = {
		bgBottomBorder: bgColorOdd,
		bgColorEven,
		bgColorOdd,
		linkColor: get(content, 'content.modAChartLinkColor'),
		linkHoverColor: get(content, 'content.modAChartHoverColor'),
		fontType: get(content, 'content.chartType'),
		other_1_column_header: get(content, 'content.other1ColumnHeader', null),
		other_2_column_header: get(content, 'content.other2ColumnHeader', null),
		other_3_column_header: get(content, 'content.other3ColumnHeader', null),
		alternatingRowColor: get(content, 'content.alternatingRowColor', null),
		copy: {
			markdown: null
		},
		styles: [
		]
	};

	// Process table data.
	const processTable = (table) => {
		const tableContent = get(table, 'content');
		tableObj.copy = {
			markdown: tableContent.tableDetails
		};

		const stylesObject = {
			name: '',
			bgColorWhenGrouped: 'transparent',
			subgroups: []
		};

		let subgroupObj = {
			rows: []
		};

		tableContent.tableContents && tableContent.tableContents.forEach((tableData) => {
			if (tableData.groupName !== '') {
				if (!isEmpty(subgroupObj)) {
					stylesObject.subgroups.push(subgroupObj);
					subgroupObj = {};
				}
				subgroupObj.name = tableData.groupName;
				subgroupObj.bgColorWhenGrouped = tableData.backgroundcolorWhenGrouped || 'transparent';
				subgroupObj.rows = [];
			}

			subgroupObj.rows.push({
				name: tableData.fontStyle,
				sizePrint: tableData.printSize,
				sizeScreen: tableData.screenSize,
				other_1: tableData.other1,
				other_3: tableData.other3,
				other_2: tableData.otherCol2
			});
		});

		if (subgroupObj !== {}) {
			stylesObject.subgroups.push(subgroupObj);
		}

		stylesObject.name = tableContent.tableTitle;
		stylesObject.index = tableContent.mtaIndex;
		tableObj.styles.push(stylesObject);
	};

	if (isArray(content)) {
		content.forEach(processTable);
	} else { processTable(content); }

	return tableObj;
}

/**
 * Create ModuleToggle options object for individaul toggle
 *
 * @param  {Object} toggle Toggle object as it comes from the API
 * @return {Object}        TOggle options formatted for ModuleToggle
 */
export function makeModuleToggleOptions(toggle) {
	const delimiter = /\s*,\s*/g;
	const values = toggle.toggleHyphenatedLabel.split(delimiter);
	const labelMobiles = toggle.toggleOptionLabel.split(delimiter);
	const labelDesktops = labelMobiles !== '' ? toggle.toggleDesktopLabel.split(delimiter) : [];
	const option = { };
	option.values = values.map((value, i) => ({ value, label: labelMobiles[i], labelDesktop: labelDesktops[i] }));
	const defaultOption = toggle.defaultLabel === '' ? option.values[0].value : toggle.defaultLabel;
	option.default = { value: defaultOption, toggled: true };
	return option;
}


/**
 * Takes all font packages that are passed in and flattens to one level, typically used for a search
 * on an attribute. For example, it’s often useful to find a package using the `id` attribute,
 * regardless of whether or not the package is a child of another package (like package siblings).
 *
 * @method  flattenPackages
 * @param   {Array}  packages  Array of packages to flatten
 * @return  {Array}  The new, flattened version of the original array
 */
export function flattenPackages(packages, type = null) {
	// Now pull all sibling packages up to the root so that we can find the correct one by `id`.
	// Could this be combined with the following `find()` function?
	const flattenedPackages = [];
	each(packages, (pack) => {
		if (type === null || pack.packageType === type) {
			flattenedPackages.push(pack);
			if (get(pack, 'siblings') && pack.siblings.length) { flattenedPackages.push(pack.siblings[0]); }
		}
	});

	return flattenedPackages || [];
}


/**
 * Takes a route with raw parameters and replaces the parmeters with the props
 * that are passed in. If there are any raw parameters left in the string that
 * didn’t match any of the props, leave them intact.
 *
 * This method assumes that the string with params will be formatted in one of
 * two specific ways:
 *
 * 		1. https://try.typography.com/?font=:fontID
 *
 * The parameter is formatted in the query string of the route
 *
 * 		2. /fonts/:font/characters
 *
 * The parameter is a segment of the route.
 *
 * It also assumes that the parameter will be followed by a forward slash (/) or
 * end of line anchor ($) in the string.
 *
 * @method replaceParams
 * @param  {String} path    Route with parameters to replace
 * @param  {Object} params  Object with param replacement values
 * @return {String} 		The formatted route with updated parameters
 */
export function replaceParams(path, params) {
	// consolidated holds the route that was passed in and is updated with param
	// replacement values, later to be returned.
	let consolidated = path;

	forOwn(params, (param, key) => {
		// Regex should match a param that’s in the query string (?font=:fontID)
		// or a parameter that is a segment in the route (/fonts/:font/characters)
		const findParam = `:${key}(/|$|&)`;

		const replaced = new RegExp(findParam, 'gi');

		// If this param isn’t found in the string, move on.
		if (param.indexOf(replaced) > -1) { return; }

		consolidated = consolidated.replace(replaced, `${param}$1`);
	});

	return consolidated;
}


/**
 * Using FontFaceObserver, load requested WOFFs and return resolve or reject
 * @method loadWOFFs
 * @param  {[type]}  woffs [description]
 * @return {[type]}  [description]
 */

export async function loadWOFFs(woffs, options) {
	let isBot = false;

	if (navigator.userAgent.toLowerCase().indexOf('googlebot') !== -1 || navigator.userAgent.toLowerCase().indexOf('prerender') !== -1) {
		isBot = true;
	}

	if (isBot) {
		return true;
	} else {
		// Lastly, have FontFaceObserver load the WOFFs.
		const collected = woffs.map(font => {
			const observer = new FontFaceObserver(font);
			return observer.load(null, NumValues.woffLoadTimeout);
		});

		return await Promise.all(collected).catch(error => {
			const err = new Error(`Error loading WOFFs - font family: ${error.family ? error.family : 'Unknown'}`);
			if (options && options.error) {
				options.error();
			}
			if (process.env.NODE_ENV === 'development' && process.env.LOG_ERRORS) {
				console.error(err); // eslint-disable-line no-console
			} else {
				Sentry.withScope(scope => {
					scope.setExtra('debug', false);
					Sentry.captureException(err);
				});
			}
		});
	}
}

/**
 * Decode the string that was encoded using the encodeLayout() method.
 * https://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings
 * @method b64DecodeUnicode
 * @param  {String}     str Encoded HTML from database
 * @return {String}     Encoded Decoded HTML
 */
export function b64DecodeUnicode(str) {
	return decodeURIComponent(Array.prototype.map.call(atob(str), (code) =>
		'%' + ('00' + code.charCodeAt(0).toString(16)).slice(-2) // eslint-disable-line prefer-template
	).join(''));
}


/**
 * Decode encoded data for display
 *
 * @method decodeData
 * @param  {String}   data The encoded data
 * @return {String}   Decoded data
 */
export function decodeData(data) {
	return b64DecodeUnicode(data);
}


/**
 * Strip HTML from string. This method creates a <DIV/>, sets the DIV’s
 * innerHTML using the string, then grabs the text version of that rendered content.
 * @method stripHTML
 * @param  {String}  html  HTML string to clean up.
 * @return {String}  Cleaned string.
 */
export function stripHTML(html) {
	const div = document.createElement('div');
	div.innerHTML = html;
	return div.textContent || div.innerText || '';
}

/**
 * Create SHA-256 encoded string
 */

export async function hashSHA256(string) {
	try {
		const utf8 = new TextEncoder().encode(string);
		const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray
			.map((bytes) => bytes.toString(16).padStart(2, '0'))
			.join('');
		return hashHex;
	} catch (err) {
		return Promise.reject(err);
	}
}
