/*
 * Constants
 */

// Selected and stored license details
export const SET_LICENSE = 'hco/Licenses/SET_LICENSE';
export const UPDATE_LICENSE = 'hco/Licenses/UPDATE_LICENSE';
export const RESET_LICENSE = 'hco/Licenses/RESET_LICENSE';
export const FOCUS_LICENSE = 'hco/Licenses/FOCUS_LICENSE';

export const SET_ERROR = 'hco/Licenses/SET_ERROR';

export const SELF_HOSTED_PLANS = 'hco/Licenses/SELF_HOSTED_PLANS';
export const SELF_HOSTED_PLANS_ERROR = 'hco/Licenses/SELF_HOSTED_PLANS_ERROR';
export const SELF_HOSTED_PLANS_SUCCESS = 'hco/Licenses/SELF_HOSTED_PLANS_SUCCESS';
