/**
 * Variables in this file are available to all stylesheets built by Webpack. You should consider creating a variable if:
 *
 * 1) It is a property that JavaScript will need to know about (like toolbar-height), or
 * 2) it gets used in a wide array of different elements (like colors, default margins, or animation durations), or
 * 3) it creates beneficial set-points in an otherwise unclear spectrum (like z-index levels), or
 * 4) it clarifies its purpose greatly by using English words (like breakpoint names), or
 * 5) we think we'll need to update it often, or through a CMS.
 *
 * If your property does not fall into one of the above reasons, it likely doesn't need to be a variable. Instead, if
 * it's just a property of an element that may itself be reused a few times, consider placing that element in its own
 * ~/styles/[element].css file, and simply using "composes" to import that element's rules elsewhere.
 */
module.exports = {
	/* Z-index Levels */
	'level-a': 1, // troposphere
	'level-b': 2, // stratosphere
	'level-c': 3, // mesosphere
	'level-d': 4, // thermosphere
	'level-e': 5, // ionosphere
	'level-f': 6, // exosphere
	'level-g': 6, // exosphere

	/* Grid */
	'large-medium-max-width': '87.5vw',
	'large-max-width': '78.85246vw',
	'x-large-max-width': '78.85246vw',

	/* Margins/Padding */
	'default-padding': '15px',
	'medium-padding': '30px',
	'large-padding': '40px',
	'default-leftRight-space': '25px',
	'max-site-width': '78.85246vw',

	'container-padding-large': 'calc(120px + (var(--large-toolbar-height) * 2))',
	'container-padding-medium': 'calc(105px + (var(--large-toolbar-height) * 2))',
	'container-padding-small': 'calc(60px + (var(--large-toolbar-height) * 2))',

	/* Gradients */
	'light-gray-to-gray-gradient': 'linear-gradient(to bottom, rgba(245,245,245,1), rgba(252,252,252,1))',

	/* Font sizes */
	'default-font-size': '15px',
	'default-line-height': '1.6',
	'menu-font-size': '15px',

	/* Toolbar settings */
	'default-toolbar-height': '42px',
	'default-toolbar-item-width': '40px',
	'large-toolbar-height': '52px',
	'large-toolbar-item-width': '44px',
	'toolbar-separator-color': 'rgb(61, 61, 61)',

	/* General content area margins/padding */
	'content-padding-bottom': 'var(--default-toolbar-height)',
	'content-padding-top': '120px',
	'totally-arbitrary-medium-bp-padding': 'calc(20% + 25px)',
	'totally-arbitrary-blog-medium-bp-padding': '20%',

	/* Page header settings */
	'page-header-small': '52px',
	'page-header-medium': '104px',
	'page-header-large': '194px',
	'page-header-height': '194px',

	/* Overview controls */
	'control-height--small': '42px',
	'control-height--large': '78px',

	/* Animation settings */
	'transition-duration-default': '300ms',
	'transition-duration-fast': '150ms',
	'transition-duration-slow': '800ms',
	'transition-easing-default': 'ease-in-out',
	'transition-easing-easeout': 'cubic-bezier(.12, .91, .65, 1)',
	'transition-easing-easeinout': 'cubic-bezier(.13, .45, 0, 1)',
	'transition-easing-gallery-tile': 'cubic-bezier(0.19, 1, 0.22, 1)',

	/* Footer settings */
	'default-footer-height': 'var(--default-toolbar-height)',
	'large-footer-height': '134px',

	/* Border Radius */
	'radius-level-xSmall': '7px',
	'radius-level-small': '8px',
	'radius-level-medium': '10px',
	'radius-level-large': '14px',

	/* Box Shadow */
	'box-shadow-small': '0 2px 3px rgba(0, 0, 0, .06)',
	'box-shadow-medium': '0px 2px 4px 0 rgba(0, 0, 0, 0.12)',
	'box-shadow-modal': '0 10px 10px rgba(0, 0, 0, 0.2)',

	/* License bar settings */
	'license-bar-default-height': '335px',
	'license-bar-collapsed-height': '110px',

	/* Forms */
	'form-padding-default': '22px',
	'form-padding-medium': '30px',
	'form-padding-large': '36px',
	'select-height-default': '29px',
	'select-height-large': '34px',

	/* Packages */
	'base-font-size': '12px',
	'package-padding': '0 15px 0 24px',
	'footer-padding-bottom': '21px',
	'min-button-height': '34px',
	'default-transition-duration': '150ms',
	'default-transition-timing': 'ease-out',

	/* Form inputs */
	'input-inset-shadow': 'inset 1px 1px 0 0 rgba(100, 100, 100, .25);',

	/* Images */
	'package-styles-width': '1500px',

	/* CSS Filters */
	'filter-blue__default--dark': 'invert(.483) sepia(1) saturate(4) hue-rotate(174deg)',

	/* Pills */
	'pill-app-subscription-title-min-height': '68px',

	/* Slider settings */
	'slider-height': '26px',

	/* White module section settings */
	'section-spacing-small': '88px',
	'section-spacing-medium': '113px',
	'section-spacing-large': '65px',
	'section-spacing-xLarge': '92px',
	'sub-section-width': '50%',
	'sub-section-gutter': '20px',

	/* Styles page */
	'styles-padding-top': '58px',
	'styles-padding-top-large': '82px',

	/* Special Offers */
	'special-offers-width': '210px',

	/* One-liners */
	'base-line-width': '2250',
	'base-line-height': '36',
	'line-padding-bottom': '5',
	'line-padding-top': 'calc(var(--base-line-height) / var(--base-line-width) * 100%)',
	'line-margin': '24'
};
