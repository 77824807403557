/*
 * Authentication constants
 */

export const LOOKUP = 'hco/Authentication/LOOKUP';
export const LOOKUP_LOADING = 'hco/Authentication/LOOKUP_LOADING';
export const LOOKUP_ERROR = 'hco/Authentication/LOOKUP_ERROR';

export const SIGNIN = 'hco/Authentication/SIGNIN';
export const SIGNIN_LOADING = 'hco/Authentication/SIGNIN_LOADING';
export const SIGNIN_ERROR = 'hco/Authentication/SIGNIN_ERROR';

export const SIGNUP = 'hco/Authentication/SIGNUP';
export const SIGNUP_LOADING = 'hco/Authentication/SIGNUP_LOADING';
export const SIGNUP_ERROR = 'hco/Authentication/SIGNUP_ERROR';

export const HANDLE_REAUTHENTICATION = 'hco/Authentication/HANDLE_REAUTHENTICATION';
